import { useRef } from "react";
import { Path, Layer, Group } from "react-konva";

import { InventoryInfo } from "./InventoryInfo";

export default function Sections({
  data,
  setData,
  selectRegularSeats,
  clickGASection,
  activeMapAction,
  activeTab,
  inventory,
  darkenColor,
}) {
  const handleSectionAction = (e, sectionId, hovering) => {
    const container = e.target.getStage().container();
    container.style.cursor = hovering ? "pointer" : "default";

    let updatedData = { ...data };
    applyFilterToSection(
      sectionId,
      hovering,
      updatedData.rows,
      updatedData.seats
    );

    setData(updatedData);
  };

  const applyFilterToSection = (sectionId, hovering, rows, seats) => {
    const section = data.sections[sectionId];

    // Get targetRows
    const targetRows = section.rows.map((rowId) => {
      const row = rows[rowId];
      return row;
    });

    targetRows.forEach((row) => {
      row.seats.forEach((seatId) => {
        const seat = seats[seatId];
        if (seat) {
          seat.hovering = hovering;
        }
      });
    });

    // Apply filter to non-zoomable section
    if (targetRows.length === 0) {
      section.hovering = hovering;
    }
  };

  const handleSectionSelect = (section) => {
    console.log("handleSectionSelect");
    // Handle GA and seated differently
    if (section?.zoomable) {
      // seated
      const seatIds = getSeatIdsForZoomableSection(
        section,
        data.rows,
        data.seats
      );
      selectRegularSeats(undefined, seatIds);
    } else {
      clickGASection(data.sections[section.sectionId]);
    }
  };

  const getSeatIdsForZoomableSection = (section, rows, seats) => {
    return Object.values(rows)
      .filter((row) => row.sectionId === section.sectionId)
      .flatMap((row) => row.seats)
      .filter((seatId) => {
        if (!seats[seatId]) return false;
        // dont include sold seats
        if (
          inventory.getSeatItem(seatId) &&
          inventory.getSeatItem(seatId)?.sold
        ) {
          return false;
        }
        // seats[seatId].selected = true;
        return true;
      });
  };

  return (
    <Layer>
      {data?.sections &&
        Object.values(data.sections).map((section) => {
          return section?.zoomable ? (
            <Path
              key={section.sectionId}
              data={section.path}
              id={section.sectionId}
              onClick={() => handleSectionSelect(section)}
              onMouseOver={(e) =>
                handleSectionAction(e, section.sectionId, true)
              }
              onMouseOut={(e) =>
                handleSectionAction(e, section.sectionId, false)
              }
              opacity={0}
              pointerEvents={"none"}
              listening={activeMapAction === 1}
            ></Path>
          ) : (
            <GASection
              key={section?.sectionId}
              section={section}
              handleSectionAction={handleSectionAction}
              handleSectionSelect={handleSectionSelect}
              activeMapAction={activeMapAction}
              activeTab={activeTab}
              inventory={inventory}
              darkenColor={darkenColor}
            />
          );
        })}
    </Layer>
  );
}

function GASection({
  section,
  handleSectionAction,
  handleSectionSelect,
  activeMapAction,
  activeTab,
  inventory,
  darkenColor,
}) {
  const visualRef = useRef();

  const getSectionFillColor = (section) => {
    console.log("getSection: ", inventory.isSectionSelected(section.sectionId));
    if (inventory.isSectionSelected(section.sectionId)) {
      console.log("section is selected");
      return "rgba(20, 20, 22, 1)";
    }
    // Check if it's a GA section and get its color
    const gaColor = inventory.getGASectionColor(section.sectionId);
    if (gaColor && activeTab === "scaling") {
      return section.hovering ? darkenColor(gaColor, 1) : gaColor;
    }

    if (section.hovering) {
      return darkenColor(section.fill, 1);
    }

    return section.fill;
  };

  return (
    <Group>
      {/* Section */}
      <Path
        fill={getSectionFillColor(section)}
        data={section.path}
        className={`path`}
        onClick={() => handleSectionSelect(section)}
        onMouseOver={(e) => handleSectionAction(e, section.sectionId, true)}
        onMouseOut={(e) => handleSectionAction(e, section.sectionId, false)}
        listening={activeMapAction !== 4}
        id={section.sectionId}
      ></Path>
      {/* Visuals, probably text */}
      <Path
        ref={visualRef}
        className='path_non_visual'
        data={section?.identifier?.path}
      ></Path>
      <InventoryInfo
        visualRef={visualRef}
        section={section}
        activeTab={activeTab}
        inventory={inventory}
      />
    </Group>
  );
}
