import { createContext } from 'react';

const EventDetailsContext = createContext({
    event: {},
    updateEvent: () => { },
    isGAOnlyEvent: false,
    setIsGAOnlyEvent: () => { },
    eventStart: null,
    storeEventStart: () => { },
    eventVisibility: null,
    storeEventVisibility: () => { },
    initialEventVisibility: null,
    updateInitialEventVisibility: () => { },
    generalOnsale: null,
    storeGeneralOnsale: () => { },
    standardAdmissionOfferHasInventory: false,
    setStandardAdmissionOfferHasInventory: () => { },
    canPublish: false,
    updateCanPublish: () => { },
    isEventPublished: false,
    setIsEventPublished: () => { },
    isEventOnsale: false,
    updateIsEventOnsale: () => { }
});

export default EventDetailsContext;