import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import { isEqual } from 'lodash'

import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { publishEvent, updateEventStatus } from '../../utilities/api';

import { formatDateTime, getFormattedTimezoneDate, getIsTimeAfterGeneralOnsaleErrorMsg, getTimezoneDate, isTimeAfterGeneralOnsale, isISOString } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import { PublishModal } from './PublishModal';
import { EventVisibilityModal } from './EventVisibilityModal';

import './eventBanner.scss'

export default function EventBanner({ eventId }) {

    const navigate = useNavigate()

    const { event, eventStart, eventVisibility, generalOnsale, canPublish, isEventPublished, setIsEventPublished, isEventOnsale, updateIsEventOnsale, storeEventVisibility, updateEvent } = useContext(EventDetailsContext)

    const statuses = {
        1: {
            type: 'draft',
            label: 'Draft'
        },
        2: {
            type: 'scheduled',
            label: 'Scheduled'
        },
        3: {
            type: 'published',
            label: 'Published'
        },
        4: {
            type: 'onsale',
            label: 'On Sale'
        },
        5: {
            type: 'ended',
            label: 'Ended'
        },
        6: {
            type: 'soldout',
            label: 'Sold Out'
        },
        7: {
            type: 'cancel',
            label: 'Cancelled'
        }
    }

    const [initialState, setInitialState] = useState()

    const [showDropdownOpen, setShowDropdownOpen] = useState(false)

    const [show, setShow] = useState(false)

    const [showSchedule, setShowSchedule] = useState(false)

    const [status, setStatus] = useState();

    const [step, setStep] = useState(1)

    const [isScheduled, setIsScheduled] = useState(false)

    const [eventVisibilityLocal, setEventVisibilityLocal] = useState(null)

    const [timeError, setTimeError] = useState(false)

    const [timeErrorMsg, setTimeErrorMsg] = useState('')

    const [isSaving, setIsSaving] = useState(false)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    // format event visibility local to correct timezone only when date is in ISO format - only on first render or when visibility is reset to initial state 
    useEffect(() => {
        console.log('reset', eventVisibilityLocal, initialState?.eventVisibility);
        if (eventVisibilityLocal && isISOString(eventVisibilityLocal)) {
            console.log('reset', eventVisibilityLocal, initialState?.eventVisibility);
            setEventVisibilityLocal(new Date(getFormattedTimezoneDate(initialState?.eventVisibility, event?.timezone)))
        }
    }, [initialState, eventVisibilityLocal])

    useEffect(() => {
        const eventVisibilityDate = eventVisibility

        console.log(eventVisibilityDate);

        setEventVisibilityLocal(eventVisibilityDate ? eventVisibilityDate : null)

        setIsScheduled(isAfter())

        // if event has ended, status: ended

        // ended 
        if (event?.status === 'complete') {
            setStatus(5)
        }

        // TODO: add sold out status 

        // if event is published; four statuses: published, onsale, cancelled 
        // event is published
        else if (isEventPublished) {
            // TODO: hook up cancelled status - published but offer dates have not passed ?

            // if event is onsale, two statuses: onsale or sold out
            if (isEventOnsale) {
                // if event is onsale in context from newly created offer but event is not onsale; publish event again to change its status 
                if (event?.status !== 'on_sale') {
                    const data = {}
                    data["eventId"] = event?.uuid;
                    data["status"] = 'on_sale'
                    updateEventStatus({ data })
                }

                // sold out  
                if (event?.soldOut) {
                    setStatus(6)
                } else {
                    // on sale
                    setStatus(4)
                }
            } else {
                // published
                setStatus(3)
            }
            // if event is not published
            // event is not published; three statuses: draft, scheduled, will be published  
        } else {
            // draft
            console.log(eventVisibility);
            if (!eventVisibility) {
                setStatus(1)
            }

            // scheduled
            // event visibility is set to future date
            // or create event flow not complete 
            else if (isAfter() || !canPublish) {
                console.log(isAfter());
                setStatus(2)
            }

            // published
            // is before today's date 
            else {
                setStatus(3)
                console.log('publish state', isEventPublished, event);
                // if event visibility changes or if canPublish changes, if not already published, publish event 
                if (!isEventPublished) handlePublish(eventVisibilityDate)
            }
        }

        setInitialState({
            eventVisibility: eventVisibilityDate,
            isScheduled: isAfter()
        })

    }, [eventVisibility, canPublish, isEventOnsale, isEventPublished, event])

    // reset scheduled date fields 
    useEffect(() => {
        if (!isScheduled && eventVisibilityLocal) setEventVisibilityLocal(initialState?.eventVisibility)
    }, [isScheduled, initialState?.eventVisibility])

    // validation for event visibility
    // - cannot be after general onsale start date
    useEffect(() => {
        setTimeError(isTimeAfterGeneralOnsale(eventVisibilityLocal, event))
        setTimeErrorMsg(getErrorMsg())
    }, [eventVisibilityLocal])

    // validation for event visibility: 
    // - cannot be after general onsale start date 
    const getErrorMsg = () => {
        if (isTimeAfterGeneralOnsale(eventVisibilityLocal, event)) {
            return getIsTimeAfterGeneralOnsaleErrorMsg(event)
        }
        else return ''
    }

    const isAfter = (visibility = eventVisibility) => {
        // is after today's date - not visible yet 
        console.log(getTimezoneDate(visibility, event?.timezone, true), getTimezoneDate(moment(), event?.timezone));
        if (visibility) {
            let visibilityDate;

            // event visibility is sometimes already in UTC format (already converted to timezone) eg. when scheduling publishing from event details
            // so check date format 
            if (isISOString(visibility)) {
                visibilityDate = moment(visibility);

            } else {
                visibilityDate = getTimezoneDate(visibility, event?.timezone, true)
            }
            return visibilityDate.isAfter(getTimezoneDate(moment(), event?.timezone))
        } else {
            return false
        }
    }

    const handleVisibility = (isScheduled) => {
        setIsScheduled(isScheduled)
    }

    const handleNext = () => {
        if (step === 3) handlePublish(undefined, undefined, !isScheduled)
        else setStep((prevStep => parseInt(prevStep) + 1))
    }

    const handleShow = (action) => {
        setShowDropdownOpen(false)
        switch (action) {
            case 'publish':
                setShow(true)
                break;

            case 'schedule':
                setShowSchedule(true)

            default:
                break;
        }
    }

    const handleEdit = () => {
        navigate(`/myevent/${eventId}/seatmap`)
        setShow(false)
    }

    // publish modal
    const handleClose = () => {
        setShow(false)
        setStep(1)
        // reset alert
        if (alert.show) {
            setAlert({
                show: false,
                variant: '',
                message: ''
            })
        }
    }

    const handleCancel = () => {
        handleClose()
        setEventVisibilityLocal(initialState?.eventVisibility)
        setIsScheduled(initialState?.isScheduled)
    }

    // schedule event visiblity modal 
    const handleCloseSchedule = () => {
        setShowSchedule(false)
    }

    const handleCancelSchedule = () => {
        handleCloseSchedule()
        setEventVisibilityLocal(initialState?.eventVisibility)
    }

    // publish event 
    const handlePublish = (visibility = eventVisibilityLocal, isScheduledArg = isScheduled, isPublishNow = false) => {
        console.log(visibility);
        publish(visibility, isScheduledArg, isPublishNow).then((res) => {
            updateEvent(res?.data)
            updateIsEventOnsale(res?.data)
            storeEventVisibility(res?.data?.eventVisibility)
        })
    }

    const publish = (visibility, isScheduled, isPublishNow) => {
        console.log(isScheduled, isPublishNow);
        return new Promise((resolve, reject) => {
            setIsSaving(true)
            let data = {};
            // 2 - scheduled 
            // 1 - on_sale 
            data['publishType'] = isScheduled ? 2 : 1;
            // if not scheduled and no visibility date or set to publish now ('as soon as publish'), set visibility to current date 
            if (!isScheduled) {
                if (!visibility || isPublishNow) {
                    visibility = moment()
                }
            }

            let visibilityDate;

            const getStatus = () => {
                const getActiveAvailabilityPeriod = (offers) => {
                    let activePeriods = [];
                    offers?.map(offer => {
                        activePeriods = [...activePeriods, ...offer?.availability?.filter(period => period.active)]
                    })
                    return activePeriods;
                }

                // published event 
                if (!isScheduled) {
                    // published or on sale 
                    const activePeriods = getActiveAvailabilityPeriod(event?.offers);

                    // check to see if event is onsale
                    const isOnSale = activePeriods.some(period => getTimezoneDate(period.starts, event.timezone).isSameOrBefore(getTimezoneDate(moment(), event.timezone)))

                    return isOnSale ? 'on_sale' : 'published'
                }

                return 'scheduled'
            }
            console.log('raw date', visibility, isISOString(visibility));
            // event visibility is sometimes already in UTC format (already converted to timezone) eg. when publishing from event details 
            // so check date format 
            if (isISOString(visibility)) {
                visibilityDate = moment(visibility)
            } else {
                console.log(isPublishNow);
                visibilityDate = getTimezoneDate(visibility, event?.timezone, !isPublishNow).format();
            }
            console.log('publish date', visibilityDate);
            data['publishDate'] = visibilityDate
            data['status'] = getStatus()
            // Remove tickets so payload isn't too large
            delete event?.tickets;
            data['event'] = {
                ...event,
                eventVisibility: visibilityDate
            };

            publishEvent({ data })
                .then((res) => {
                    if (!isSaving) {
                        setIsSaving(false)
                        handleClose()
                        handleCloseSchedule()
                        setIsEventPublished(!isScheduled)
                        resolve(res)
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info. Please try again.'
                    })
                })
        })
    }

    // schedule event visibility
    const handleSubmit = () => {
        const isScheduledLocal = isAfter(eventVisibilityLocal)
        // if can publish, publish event
        if (canPublish) { // status wil be published or on sale 
            handlePublish(eventVisibilityLocal, isScheduledLocal)
        } else {
            // set event status as Scheduled 
            setStatus(2)
            handleCloseSchedule()
            // update event visibility
            storeEventVisibility(getTimezoneDate(eventVisibilityLocal, event?.timezone, true))
        }
    }

    const checkDisabled = () => {
        console.log('check disabled');
        // step 2 and scheduled
        // is disabled if null or visibility is same as in context 
        // visibility in context is utc (convert to timezone) and visibility is in local time (already started from timezone)
        if (step === 2 && isScheduled) {
            return (isEqual(eventVisibility, eventVisibilityLocal) || isEqual(new Date(getFormattedTimezoneDate(eventVisibility, event?.timezone)), eventVisibilityLocal))
        }
    }

    return (
        <>
            <div className='banner banner--sticky' id="banner">
                <div className='banner-wrapper'>
                    <Stack gap={1} className="event-details">
                        <h1 className='normal m-0 event-title'>{event?.name}</h1>
                        <span className='subtitle subtitle--dark m-0'>
                            <span>{formatDateTime(getTimezoneDate(eventStart, event?.timezone), 'dateOnly')}</span>
                            <span className='time'>{formatDateTime(getTimezoneDate(eventStart, event?.timezone), 'timeOnly')}</span>
                        </span>
                    </Stack>
                    <Dropdown show={showDropdownOpen} id='event-status-dropdown' onToggle={() => setShowDropdownOpen(!showDropdownOpen)}>
                        <Dropdown.Toggle variant="default" id="status-dropdown"
                            className={`badge badge-lg badge--${statuses[status]?.type}`}
                        >
                            {statuses[status]?.label}
                        </Dropdown.Toggle>
                        {setShowDropdownOpen && (
                            <Dropdown.Menu className='w-xl dropdown-menu-lg'>
                                <span className='normal text-muted'>{isAfter() ? 'Scheduled on' : 'Event visibility'}:</span>
                                <h1 className='normal'>
                                    {eventVisibility ? (
                                        <>
                                            <span>{formatDateTime(getTimezoneDate(eventVisibility, event?.timezone), 'dateOnly')}</span>
                                            <span className='time'>{formatDateTime(getTimezoneDate(eventVisibility, event?.timezone), 'timeOnly')}</span>
                                        </>
                                    ) : (
                                        "As soon as published"
                                    )}
                                </h1>
                                <span className="subtitle d-block mt-3">Timezone: {event?.timezone?.value} ({event?.timezone?.abbr})</span>
                                {/* not visible yet (published) and event has not ended */}
                                {(status !== 3 && status !== 5) && (
                                    <>
                                        {!canPublish && (
                                            <p className='mt-3 info-text info-text-md  info-text-danger'><span className="caption text-danger">Event {eventVisibility ? 'will' : 'cannot'} be published {eventVisibility ? 'at scheduled time after' : 'without'} completing event details, event setting, and seat map</span></p>
                                        )}
                                        {/* event doesn't have event visibility or is after today's date (draft or scheduled at later date) */}
                                        {(!eventVisibility || isScheduled || !canPublish) && (
                                            <Stack direction="horizontal" className='btn-group-flex btn-group-flex-justify btn-group-flex-sm seperator seperator-sm'>
                                                <Button
                                                    variant="outline-light"
                                                    onClick={() => handleShow('schedule')}
                                                >
                                                    {eventVisibility ? 'Reschedule' : 'Schedule time'}</Button>

                                                <Button
                                                    disabled={!canPublish}
                                                    onClick={() => handleShow('publish')}
                                                >Publish now</Button>
                                            </Stack>
                                        )}
                                    </>
                                )}
                            </Dropdown.Menu>
                        )}
                    </Dropdown>
                </div>
            </div>

            <PublishModal show={show} event={event} step={step} isScheduled={isScheduled} eventStart={eventStart} eventVisibility={eventVisibilityLocal} setEventVisibility={setEventVisibilityLocal} error={timeError} errorMsg={timeErrorMsg} isDisabled={checkDisabled()} isSaving={isSaving} alert={alert} handleVisibility={handleVisibility} handleEdit={handleEdit} handleNext={handleNext} handleCancel={handleCancel} />

            <EventVisibilityModal show={showSchedule} event={event} initialEventVisibility={eventVisibility} eventVisibility={eventVisibilityLocal} setEventVisibility={setEventVisibilityLocal} generalOnsale={generalOnsale} eventStart={new Date(getTimezoneDate(eventStart, event?.timezone))} error={timeError} errorMsg={timeErrorMsg} isSaving={isSaving} handleSubmit={handleSubmit} handleCancel={handleCancelSchedule} />
        </>
    );
}
