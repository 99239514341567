import React, { useEffect } from 'react';

import { formatCurrency, formatNumber } from '../../../../../../utilities/helpers'

import Stack from 'react-bootstrap/Stack'

export default function FinancialInfo({ priceLevels, sumRevenues, sumCapacity }) {

    return (
        <>
            <Stack direction="horizontal">
                <Stack as="ul" gap={2}>
                    <p className='caption fw-normal'>Potential revenue</p>
                    <li className='small small-bold'>{formatCurrency(sumRevenues(priceLevels))}</li>
                </Stack>
                <Stack as="ul" gap={2}>
                    <p className='caption fw-normal'>Sellable capacity</p>
                    <li className='small small-bold'>{formatNumber(sumCapacity())}</li>
                </Stack>
                <Stack as="ul" gap={2}>
                    <p className='caption fw-normal'>Avg per seat</p>
                    <li className='small small-bold'>{formatCurrency((sumRevenues(priceLevels)) / sumCapacity())}</li>
                </Stack>
            </Stack>
            <span className="text-muted caption">Kills and holds are excluded</span>
        </>
    );
}
