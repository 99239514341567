import React from "react"
import { useParams } from "react-router-dom";
import { PackageSettingsWrapper } from "../../components";

export default function PackageSettingsPage() {
  const { uuid } = useParams()

  return (
    <>
      <PackageSettingsWrapper uuid={uuid} />
    </>
  )
}