import saveAs from 'file-saver';
import ExcelJS from 'exceljs';
import moment from 'moment'

// global state options 
export const stateOpt = [
    { value: 'AK', name: 'Alaska' },
    { value: 'TX', name: 'Texas' },
    { value: 'AL', name: 'Alabama' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DC', name: 'District Of Columbia' },
    { value: 'DE', name: 'Delaware' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'IA', name: 'Iowa' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MD', name: 'Maryland' },
    { value: 'ME', name: 'Maine' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MT', name: 'Montana' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NY', name: 'New York' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VA', name: 'Virginia' },
    { value: 'VT', name: 'Vermont' },
    { value: 'WA', name: 'Washington' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WY', name: 'Wyoming' }
];

// only letters
export const namePatternMatch = "^[a-zA-Z]+$"

// generate random color -> used in seat map and offers to generate random colors for price levels and custom offers 
export const generateRandomColor = () => {
    // Generate random values for red, green, and blue - between 0 and 255
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Create the color string in hexadecimal format - #RRGGBB
    const color = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;

    return color;
}

// check if user has permission
export const checkPermission = (allPermissions, userPermissions, id) => {
    const permission = allPermissions?.find(perm => perm.id === id);

    return userPermissions?.organization_permissions?.some(userPerm => userPerm?.name === permission?.name);
}

const checkUrl = (url) => {
    var expression = /login|\/signup|register/g;
    var regex = new RegExp(expression);
    return regex.test(url);
};

export const fullHeightContainer = (el) => {
    el.classList.add('container--full-height');

    const root = document.getElementById('root');

    root.classList.add('full-height-flex');
};

export const removeFullHeightContainer = (el) => {
    el.classList.remove('container--full-height');

    const root = document.getElementById('root');

    root.classList.remove('full-height-flex');
};

export const changeBackground = url => {
    if (checkUrl(url)) {
        document.body.classList.add('backgroundWhite')
    }
    else {
        document.body.classList.remove('backgroundWhite')
    }
}

// add sidebar container- these pages contain sidebar 
export const toggleContainer = (url) => {
    if (/package|\/myevent|\/settings/g.test(url)) {
        document.querySelector('#main-container')?.classList.remove('container') // remove container class 
        document.querySelector('#main-container')?.classList.add('sidebar-container') // add sidebar container class 
    } else {
        document.querySelector('#main-container')?.classList.add('container')
        document.querySelector('#main-container')?.classList.remove('sidebar-container')
    }
}

// is string (date) in UTC format 
export const isISOString = (date) => {
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return iso8601Regex.test(date);
}

// reuseable validation - date cannot be after event start 
// used for general onsale, event visibility -> event details, availability period -> offers, publish
// used for scheduled contact attendees 
export const isTimeAfterEventStart = (date, event, start) => {
    return moment(date).isAfter(moment(start || getFormattedTimezoneDate(event?.start, event?.timezone)))
}

// reuseable validation - get date cannot be after event start error message
// used for general onsale, event visibility -> event details, availability period -> offers, publish
// used for scheduled contact attendees 
export const getIsTimeAfterEventStartErrorMsg = (event, start) => {
    return `Time cannot be after event start time (${formatDateTime(moment(start || getTimezoneDate(event?.start, event?.timezone)), 'timeOnly')})`
}

// reuseable validation - date cannot be after general onsale 
// used for event visibility -> event details, schedule publish date (event visibility) -> publish
export const isTimeAfterGeneralOnsale = (date, event, generalOnsale) => {
    return (generalOnsale || event?.generalOnsale ? moment(date).isAfter(moment(generalOnsale || getFormattedTimezoneDate(event?.generalOnsale, event?.timezone))) : false)
}

// reuseable validation - date cannot be after general onsale 
// used for event visibility -> event details, schedule publish date (event visibility) -> event status
export const getIsTimeAfterGeneralOnsaleErrorMsg = (event, generalOnsale) => {
    return `Time cannot be after general on-sale time (${formatDateTime(moment(generalOnsale || getTimezoneDate(event?.generalOnsale, event?.timezone)), 'timeOnly')})`
}

// reuseable validation - date cannot be before event visibility
// used for general onsale -> event details, edit general onsale availability period -> offers
export const isTimeBeforeEventVisibility = (date, event, visibility) => {
    return (visibility || event?.eventVisibility ? (moment(date).isBefore(moment(visibility || getFormattedTimezoneDate(event?.eventVisibility, event?.timezone)))) : false)
}

// reuseable validation - get date cannot be before event visibility error message
// used for general onsale -> event details, edit general onsale availability period -> offers
export const getIsTimeBeforeEventVisibilityErrorMsg = (event, visibility) => {
    return `Time cannot be before event visibility time (${formatDateTime(moment(visibility || getTimezoneDate(event?.eventVisibility, event?.timezone)), 'timeOnly')})`
}

export const calculateFees = (ticket, feeStructure, taxRates) => {
    let b = {}
    const price = ticket?.price || ticket?.offerPrice
    if (parseInt(price) < 50) b['serviceFees'] = feeStructure?.primaryUnder50 ? feeStructure?.primaryUnder50 : 1;
    if (parseInt(price) >= 50) b['serviceFees'] = ((feeStructure?.primaryOver50 ? (feeStructure?.primaryOver50 / 100) : 0.021) * price);
    if (parseFloat(price)) b['paymentProcessingFee'] = Number((((price * (feeStructure?.stripeServicePecentage / 100))) + feeStructure?.stripeCharge).toFixed(2));
    b['paymentProcessingFee'] = parseFloat(b['paymentProcessingFee'])
    b['ticketPrice'] = parseFloat(price);
    b['tax'] = (taxRates?.combinedTaxRate / 100) * price
    b['facilityFee'] = ticket?.fee ? parseFloat(ticket?.fee) : 0
    b['buyerTotal'] = parseFloat(price) + parseFloat(b.serviceFees) + parseFloat(b.facilityFee) + parseFloat(b.paymentProcessingFee) + parseFloat(b.tax)
    b['payout'] = parseFloat(price) + parseFloat(b.facilityFee)

    let stripeFee = Math.round(feeStructure?.stripeServicePecentage * 1000) / 100000
    b['localTaxRate'] = taxRates?.combinedTaxRate / 100
    b['salesTax'] = b.ticketPrice > 0 ? parseFloat(((b.ticketPrice + b.facilityFee + b.serviceFees) * b.localTaxRate).toFixed(2)) : 0
    b['costWithFees'] = b.ticketPrice > 0 ? b.ticketPrice + b.facilityFee + b.serviceFees + b.salesTax : 0
    b['processingFee'] = b.ticketPrice > 0 ? parseFloat(((b.ticketPrice + b.facilityFee + b.serviceFees + b.salesTax) * stripeFee + feeStructure?.stripeCharge).toFixed(2)) : 0
    b['chargeBackProtection'] = b.ticketPrice > 0 ? parseFloat(((Math.round(((b.costWithFees + b.processingFee) * 0.004) * 100) / 100) + 0.01).toFixed(2)) : 0
    b['totalDue'] = b.ticketPrice > 0 ? b.costWithFees + b.processingFee + b.chargeBackProtection : 0
    return b
}

export const formatNumber = (num) => {
    return parseFloat(num || 0).toLocaleString()
}

// currency changes to what the host is set to 
// rounds decimals to 2 
// adds comma separator
export const formatCurrency = (num) => {
    return parseFloat(num || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const removeHyphens = (string) => {
    return string?.replaceAll('_', ' ');
}

// remove hyphens and capitalize first letter of each word 
const formatString = (string) => {
    let formattedString = removeHyphens(string);
    return formattedString = capitalizeString(formattedString)

}

// capitalize first letter of each word
export const capitalizeString = string => {
    return string ? string.split(" ").map(arr => arr.charAt(0).toUpperCase() + arr.substring(1)).join(' ') : ''
}

// format phone number
export const formatPhoneNumber = (number) => {
    return number ? `${number?.toString().substring(0, 2)} (${number?.toString().substring(2, 5)}) ${number?.toString().substring(5, 8)}-${number?.toString().substring(8)}` : 'N/A'
}

// sort attractions by their order
export const sortAttractions = attractions => {
    return attractions.sort((a, b) => a.order - b.order);
}

// format short date for tickets
// display only date and time
export const formatShortDate = (date, formatter) => {
    switch (formatter) {
        case 'dateOnly':
            return date?.format('MMM D, YYYY')

        default:
            return date?.format('MMM D, h:mm A')
    }
}

// format full date 
export const formatDateTime = (date, formatter) => {
    switch (formatter) {
        case 'dateOnly':
            return date?.format('ddd, MMM D, YYYY')

        case 'timeOnly':
            return date?.format('h:mm A')

        default:
            return date?.format('ddd, MMM D, YYYY h:mm A')
    }
}

// converts date to timezone 
// adjusts its UTC offset based on th provided offset 
// returns moment object - used with other format dates helper methods 
export const getTimezoneDate = (date, timezone, keepLocalTime = false) => {
    if (!date) return
    return moment(date).utcOffset(timezone?.offset, keepLocalTime)
}

// calls getTimezoneDate to convert date to timezone 
// returns string - used for date inputs and to check if two dates are equal 
export const getFormattedTimezoneDate = (date, timezone) => {
    // console.log(date, timezone);
    if (!date) return
    return getTimezoneDate(date, timezone).format("YYYY-MM-DD HH:mm:ss")
}

// format full address 
export const formatAddress = (obj) => {
    return `${obj?.address[0]?.address_1}, ${capitalizeString(obj?.address[0]?.city)}, ${obj?.address[0]?.state.toUpperCase()}, ${obj?.address[0]?.zipcode}, ${obj?.address[0]?.country.toUpperCase()}`
}

// format address - city, state 
export const formatShortAddress = (obj) => {
    if (!obj) return
    return `${capitalizeString(obj?.address ? obj?.address[0]?.city : obj.city)}, ${obj?.address ? obj?.address[0]?.state?.toUpperCase() : obj.state.toUpperCase()}`
}

export const formatPermissions = (permissions) => {
    return permissions.reduce(function (r, a) {
        r[a.attributes.key] = r[a.attributes.key] || [];
        r[a.attributes.key].push({
            id: a.id,
            name: a.attributes.name
        });
        return r;
    }, Object.create(null));
}

// creating and getting existing member have different object properties
// creating member has name and role property
export const formatMembers = (members) => {
    let arr = [];
    members.map(member => arr.push({ firstName: member?.firstName || member?.name.split(' ')[0], lastName: member?.lastName || member?.name.split(' ')[1], role: member?.organization_role || member?.role, email: member?.email, uuid: member?.uuid, pending: member?.pending }))
    return arr
}

// used in order list and custom reports 
export const getTransactionTypes = () => {
    return [
        {
            label: 'Primary',
            value: 'primary'
        },
        {
            label: 'Resales',
            value: 'resale',
        },
        {
            label: 'Transfers',
            value: 'transfer'
        },
        {
            label: 'Refunds',
            value: 'refund'
        }
    ];
}


export const getAttendeeTransactionType = (attendanceType, ticket) => {

    if (attendanceType === 'ticket') {

        if (ticket?.resale) return 'Resale'

        if (ticket?.transferred) return 'Transferred'

        else return 'Primary'
    }
    if (attendanceType === 'guestPass') return 'Guest Pass'
}

export const getTicketTransactionType = (ticket) => {
    if (ticket?.resale) return 'Resale'

    if (ticket?.transferred) return 'Transferred'

    else return 'Primary'
}

// get ticket object 
// used in orders ticket row
export const getTicket = (order, field) => {
    let obj;

    // transfer tickets 
    if (order.status === 'completeFromTransfer') obj = order?.details?.details?.ticket

    // resale tickets 
    else if (order?.details?.listing) obj = order?.details?.listing?.tickets[0]

    // primary tickets
    else obj = order?.tickets

    if (obj) {
        if (field) {
            return obj?.map(o => o[field])
        } else {
            return obj
        }
    }
}

// get feeDetails object 
// used in custom reports order table 
export const getFees = (order, field) => {
    let obj;

    // transfer tickets 
    if (order.status === 'completeFromTransfer') obj = order?.details?.details?.feeDetails

    // primary and resale tickets
    else obj = order?.details?.feeDetails

    if (obj) {
        if (field) {
            return obj[field]
        } else {
            return obj
        }
    }
}

// used in resale event report
export const getRoyalties = (order, feeStructure) => {
    let fees = feeStructure || order?.feeStructure;
    let buyerFee = (order?.total * fees.secondaryServiceFeeBuyer / 100)
    let sellerFee = (order?.total * fees.secondaryServiceFeeSeller / 100)
    let blockticketsRoyalty = (buyerFee + sellerFee) / 2
    return parseFloat(blockticketsRoyalty)
}

export const isMatching = (input1, input2) => {
    // compare strings only if they are not blank
    if (input1 !== '' && input2 !== '') {
        return input1 === input2
    }
}

// event is past 
export const isEventPast = event => {
    return event?.status === 'complete'
}

export const emailPatternMatch = (val) => {
    // email regex pattern 
    const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g
    return val ? new RegExp(emailPattern).test(val) : true
}

export const getUrl = (link, isGAOnlyEvent, params) => {
    const url = isGAOnlyEvent ? link : `${link}/tickets`
    return params ? `${url}${params}` : url
}

export const copy = (text, setter, isGAOnlyEvent, params) => {
    const url = getUrl(text, isGAOnlyEvent, params)
    /* Copy the text */
    navigator.clipboard.writeText(url);
    if (setter) setter(true)
}

let workbookName;

export const exportData = (data, exportedData, capacity) => {
    workbookName = exportedData;
    const excel = create();
    const [
        workbook,
        worksheet
    ] = excel;

    // add columns
    worksheet.columns = addColumns((Object.keys(format(...data))), worksheet);

    worksheet.columns.forEach(function (column, i) {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            // set all columns to text format 
            worksheet.getColumn(i + 1).numFmt = '@'
            var columnLength = cell.value ? cell.value.toString().length : 20
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        // column min width of 20
        column.width = maxLength < 20 ? 20 : maxLength;
    });

    // make the header bold
    // in Excel the rows are 1 based instead of 0 based
    worksheet.getRow(1).font = { bold: true };

    // add rows
    worksheet.addRows(addRows(Object.values(data), worksheet));

    // format rows
    if (workbookName === 'orders') {
        // custom date column - purchased date
        worksheet.getColumn(3).numFmt = 'ddd, mmm dd, yyyy h:mm AM/PM';
        worksheet.getColumn(3).width = 30;
        // number column - quantity
        numberFormat(4, worksheet);
        // ticket type column
        worksheet.getColumn(6).eachCell((cell) => {
            cell.alignment = { wrapText: true };
        });
        // currency column - paid
        currencyFormat(7, worksheet);
    }

    if (workbookName === 'sales') {
        currencyFormat(2, worksheet)

        numberFormat(3, worksheet)

        currencyFormat(4, worksheet)

        currencyFormat(5, worksheet)
        // if two tickets, rowCount = 3
        worksheet.getCell(`A${worksheet.rowCount + 1}`).value = 'Total'
        // if two tickets, rowCount = 4
        worksheet.getRow(`${worksheet.rowCount}`).font = { bold: true }

        if (worksheet.getColumn(5).key === 'net_sales') {
            worksheet.getCell(`A${worksheet.rowCount + 1}`).value = 'Tickets remaining'
            // if two tickets, rowCount = 5
            worksheet.getRow(`${worksheet.rowCount}`).font = { bold: true }
            worksheet.getCell(`A${worksheet.rowCount + 1}`).value = 'Capacity'
            // if two tickets, rowCount = 6
            worksheet.getRow(`${worksheet.rowCount}`).font = { bold: true }
        }
        // for the total row - resale tickets report has less rows than primary tickets report
        let rowCount;
        if (worksheet.getColumn(5).key === 'royalties') {
            rowCount = worksheet.rowCount
        }
        else {
            rowCount = worksheet.rowCount - 2
        }

        // total row
        addCells('C', rowCount, worksheet)
        addCells('D', rowCount, worksheet)
        addCells('E', rowCount, worksheet)

        if (worksheet.getColumn(5).key === 'net_sales') {
            // capacity row 
            worksheet.getCell(`C${worksheet.rowCount}`).value = capacity

            // tickets remaining row 
            worksheet.getCell(`C${worksheet.rowCount - 1}`).value = { formula: `SUM(C${worksheet.rowCount} - C${worksheet.rowCount - 2})` }
        }
    }

    worksheet.eachRow({ includeEmpty: true }, function (row, _) {
        row.eachCell({ includeEmpty: true }, function (cell, _) {
            cell.alignment = {
                vertical: 'middle',
                horizontal: 'left'
            }

        });
    })

    // save excel worksheet
    saveFile(workbook).then().catch((err) => alert(err.message));
};

// tell excel to format values into numbers for column
const numberFormat = (colNum, worksheet) => {
    return worksheet.getColumn(colNum).numFmt = '#,##0';
}

// tell excel to format values into currency for column
const currencyFormat = (colNum, worksheet) => {
    return worksheet.getColumn(colNum).numFmt = '"$"#,##0.00;[Red]\-"$"#,##0.00';
}

// add all cells from column
const addCells = (column, rowCount, worksheet) => {
    worksheet.getCell(`${column}${rowCount}`).value = { formula: `SUM(${column}2:${column}${rowCount - 1})` }
}

const addColumns = (names) => {
    return names.map((name) => {
        return {
            header: `${formatString(`${name}`)}`,
            key: `${name}`
        };
    });
};

const addRows = (data) => {
    return data.map((val) => {
        return format(val);
    });
};

const format = (data) => {
    switch (workbookName) {
        case 'orders':
            return formatOrder(data)

        case 'attendees':
            return formatAttendee(data)

        case 'sales':
            return formatSale(data)

        default:
            return;
    }
}

const formatOrder = (order) => {
    console.log(order);
    const { orderId, users_permissions_user, processedAt, total, intentDetails, timezone, tickets } = order;

    return {
        order: orderId,
        purchased_by: `${capitalizeString(`${users_permissions_user?.firstName} ${users_permissions_user?.lastName}`)}`,
        purchase_date: formatDateTime(getTimezoneDate(processedAt, timezone)),
        quantity: tickets?.length,
        transaction_type: `${getTicketTransactionType(tickets[0] || order?.details?.listing?.tickets[0])}`,
        ticket_type: getTicket(order, 'name').join('\n'),
        paid: total,
        paid_by: total !== 0 ? `${intentDetails?.charges?.data[0]?.payment_method_details?.card?.brand} ${intentDetails?.charges?.data[0]?.payment_method_details?.card?.last4}` : 'N/A'
    };
}

const formatAttendee = (attendee) => {
    const { users_permissions_user, attendanceType, ticket, guest_pass, checkedIn, checkedInTime, timezone } = attendee;
    return {
        ticket_buyer: `${capitalizeString(`${users_permissions_user?.firstName} ${users_permissions_user?.lastName}`)}`,
        email: users_permissions_user.email,
        phone_number: `${users_permissions_user.phoneNumber ? `${formatPhoneNumber(users_permissions_user?.phoneNumber)}` : `${formatPhoneNumber(guest_pass?.phoneNumber)}`}`,
        transaction_type: `${getAttendeeTransactionType(attendanceType, ticket)}`,
        ticket_type: ticket?.name || guest_pass?.name || 'N/A',
        status: checkedIn ? `Checked in ${formatDateTime(getTimezoneDate(checkedInTime, timezone))}` : 'Check in'
    };
}

const formatSale = (sale) => {
    const { ticketType, gross, net, price, quantity, details } = sale;
    return {
        ticket_type: ticketType || details?.listing?.tickets[0]?.name,
        price: !details?.listing ? parseFloat(price) : details?.listing?.askingPrice,
        quantity: !details?.listing ? parseFloat(quantity) : details?.listing?.quantity,
        gross: !details?.listing ? parseFloat(gross) : parseFloat(details?.listing?.pricing?.ticketCostWithFees),
        ...!details?.listing && { net_sales: parseFloat(net ? net : 0) },
        ...(details?.listing && { royalties: parseFloat(getRoyalties(sale)) })
    }
}

const create = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(workbookName, {
        properties: { defaultColWidth: 20 },
        pageSetup: { orientation: 'landscape', showGridLines: true }
    });

    return [
        workbook,
        sheet
    ];
};

const saveFile = async (workbook) => {
    workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
            new Blob(
                [
                    buffer
                ],
                {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                }
            ),
            `${workbookName} - ${new Date()}.xlsx`
        );
    });
};

// used to upload images in create event settings 
export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const isProd = () => {
    return window.location.host === 'admin.blocktickets.xyz'
}

const isPreview = () => {
    return window.location.host === 'admin.preview.blocktickets.xyz'
}

const isDevelopment = () => {
    return window.location.host === 'admin.development.blocktickets.xyz'
}

const isLocal = () => {
    return window.location.host.includes('localhost')
}

export const timezones = (abbr) => {
    let zones = [
        {
            "value": "Eastern Daylight Time",
            "abbr": "EDT",
            "offset": -4,
            "isdst": true,
            "text": "(UTC-04:00) Eastern Daylight Time (US & Canada)",
            "utc": [
                "America/Detroit",
                "America/Havana",
                "America/Indiana/Petersburg",
                "America/Indiana/Vincennes",
                "America/Indiana/Winamac",
                "America/Iqaluit",
                "America/Kentucky/Monticello",
                "America/Louisville",
                "America/Montreal",
                "America/Nassau",
                "America/New_York",
                "America/Nipigon",
                "America/Pangnirtung",
                "America/Port-au-Prince",
                "America/Thunder_Bay",
                "America/Toronto"
            ],
            "stateAbbreviation": [
                "me",
                "ny",
                "pa",
                "mi",
                "in",
                "oh",
                "wv",
                "va",
                "nc",
                "sc",
                "ga",
                "fl"
            ]
        },
        {
            "value": "Central Standard Time",
            "abbr": "CDT",
            "offset": -5,
            "isdst": true,
            "text": "(UTC-06:00) Central Time (US & Canada)",
            "utc": [
                "America/Chicago",
                "America/Texas",
                "America/Louisiana",
                "America/Indiana/Knox",
                "America/Indiana/Tell_City",
                "America/Matamoros",
                "America/Menominee",
                "America/North_Dakota/Beulah",
                "America/North_Dakota/Center",
                "America/North_Dakota/New_Salem",
                "America/Rainy_River",
                "America/Rankin_Inlet",
                "America/Resolute",
                "America/Winnipeg",
                "CST6CDT"
            ],
            "stateAbbreviation": [
                "tx",
                "la",
                "ar",
                "ms",
                "al",
                "tn",
                "ok",
                "ks",
                "mo",
                "il",
                "wi",
                "mn",
                "nd",
                "sd",
                "ne",
                "ia",
                "ky"
            ]
        },
        {
            "value": "Mountain Standard Time",
            "abbr": "MDT",
            "offset": -6,
            "isdst": true,
            "text": "(UTC-07:00) Mountain Time (US & Canada)",
            "utc": [
                "America/Boise",
                "America/Cambridge_Bay",
                "America/Denver",
                "America/Edmonton",
                "America/Inuvik",
                "America/Ojinaga",
                "America/Yellowknife",
                "MST7MDT"
            ],
            "stateAbbreviation": [
                "mt",
                "id",
                "wy",
                "ut",
                "co",
                "az",
                "nm"
            ]
        },
        {
            "value": "Pacific Standard Time",
            "abbr": "PST",
            "offset": -8,
            "isdst": false,
            "text": "(UTC-08:00) Pacific Standard Time (US & Canada)",
            "utc": [
                "America/Los_Angeles",
                "America/Tijuana",
                "America/Vancouver",
                "PST8PDT"
            ],
            "stateAbbreviation": [
                "wa",
                "or",
                "ca",
                "nv"
            ]
        },
    ]

    let offset = zones.find(zone => zone.abbr === abbr)

    return offset
}