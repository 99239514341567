import { useCallback, useEffect, useState } from 'react';
import { Stage, Rect, Layer } from 'react-konva';
import useImage from 'use-image';
import chroma from 'chroma-js'

import { useActions } from "../ActionsProvider";
import { useInventory } from "../../../InventoryProvider";
import { Sections } from "./Sections";
import { Seats } from "./Seats";

export default function SeatMap({
    data,
    setData,
    selectRegularSeats,
    clickGASection,
    activeTab,
    background
}) {
    const inventory = useInventory();

    const { stageRef, scale, setScale, activeMapAction, calculateScalePercentage, originalScaleRef } = useActions();
    const [image] = useImage(background?.url);

    const originalWidth = background?.width; // Original content width
    const originalHeight = background?.height; // Original content height
    const aspectRatio = originalWidth / originalHeight;

    const [width, setWidth] = useState(0);

    const [height, setHeight] = useState(0);

    const [offset, setOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const resizeStage = () => {
            const map = document.getElementById('map');
            const containerWidth = map.clientWidth;
            const containerHeight = map.clientHeight;
            const maxByWidth = containerWidth;
            const maxByHeight = containerHeight * aspectRatio; // because we divide by height we multiply here

            let newWidth, newHeight;
            if (maxByWidth < maxByHeight) { // If width is the limiting factor
                newWidth = containerWidth;
                newHeight = containerWidth / aspectRatio;
            } else { // If height is the limiting factor or if they are equal
                newWidth = containerHeight * aspectRatio;
                newHeight = containerHeight;
            }

            const scaleX = newWidth / originalWidth;
            const scaleY = newHeight / originalHeight;
            const scale = Math.min(scaleX, scaleY) * 0.8;
            originalScaleRef.current = scale;
            setScale(scale); // Might have to come back to this

            setWidth(containerWidth);
            setHeight(containerHeight);
            // Actually dk if the scaleX and scaleY are needed, might be able to just use scale
            const adjustedOffsetX = (originalWidth - (containerWidth / scale)) / 2;
            const adjustedOffsetY = (originalHeight - (containerHeight / scale)) / 2;
            setOffset({ x: adjustedOffsetX, y: adjustedOffsetY })
        }

        resizeStage();
        window.addEventListener('resize', resizeStage);

        return () => window.removeEventListener('resize', resizeStage);
    }, [])

    const handleWheel = useCallback((e) => {
        e.evt.preventDefault();

        const scaleBy = 1.1;
        const stage = stageRef.current;
        const oldScale = stage.scaleX();

        const pointer = stage.getPointerPosition();
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        let direction = e.evt.deltaY > 0 ? -1 : 1;

        let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        // Cap scale
        const wouldBeScale = calculateScalePercentage(newScale);
        if (wouldBeScale > 200 || wouldBeScale < 80) {
            newScale = oldScale;
        };

        setScale(newScale); // Update the scale state

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        stage.position(newPos);
    }, [stageRef]);

    const darkenColor = (color, amount) => chroma(color).darken(amount).hex();

    return (
        <Stage
            draggable
            ref={stageRef}
            width={width}
            height={height}
            scaleX={scale}
            scaleY={scale}
            onWheel={handleWheel}
            offset={{ x: offset.x, y: offset.y }}
        >
            <Layer>
                <Rect
                    width={originalWidth}
                    height={originalHeight}
                    fillPatternImage={image}
                />
            </Layer>
            <Seats
                data={data}
                setData={setData}
                selectRegularSeats={selectRegularSeats}
                activeMapAction={activeMapAction}
                darkenColor={darkenColor}
                inventory={inventory}
            />
            <Sections
                data={data}
                setData={setData}
                selectRegularSeats={selectRegularSeats}
                clickGASection={clickGASection}
                activeTab={activeTab}
                activeMapAction={activeMapAction}
                inventory={inventory}
                darkenColor={darkenColor}
            />
        </Stage>
    )
}