import React, { useState, useEffect, useContext } from 'react';

import AuthService from '../../../utilities/services/auth.service';
import UserContext from '../../../context/User/User';
import EventDetailsContext from '../../../context/EventDetails/EventDetails';

import { checkPermission, formatCurrency, formatNumber } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { TrackingLink } from './TrackingLink'
import { EmptyContainer } from '../../EmptyContainer';
import { DeleteModal } from '../../DeleteModal';

export default function TrackingLinks({ promos, removeLink, isRemoving, handleClick }) {

    const { orgPermissions } = useContext(UserContext)

    const { isGAOnlyEvent } = useContext(EventDetailsContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [show, setShow] = useState(false)

    // tracking id to delete
    const [id, setId] = useState()

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 12));

    }, [orgPermissions])

    const handleShow = () => setShow(true);

    const handleClose = () => setShow(false);

    const handleDelete = () => {
        removeLink(id).then(() => {
            handleClose()
        })
    }

    const totalViews = (object) => {
        return formatNumber(object?.reduce((accumulator, object) => {
            return accumulator + (object?.views);
        }, 0));
    }

    const totalTicketsSold = (object) => {
        return formatNumber(object?.reduce((accumulator, object) => {
            return accumulator + (object?.ticketsSold);
        }, 0));
    }

    const totalGross = (object) => {
        return formatCurrency(object?.reduce((accumulator, object) => {
            return accumulator + parseFloat(object?.grossSales);
        }, 0));
    }

    return (
        <>
            <Stack direction='horizontal' className='mb-5'>
                <Button
                    size="lg"
                    variant="outline-light"
                    onClick={handleClick}
                    className={`btn-plus ms-auto ${!hasPermission ? 'btn-link-disabled' : ''}`}>
                    Create tracking link
                </Button>
            </Stack>
            <Card body>
                {promos && promos.length > 0 ? (
                    <div className="list-table eight-col" role="table">
                        <div className="flex-row list-table-header" role="rowgroup">
                            <div className='list-table-col list-table-col-header' role="columnheader">
                                <span>Name</span>
                            </div>
                            <div className="list-table-col list-table-col-header xl" role="columnheader">
                                <span>Link</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Views</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Tickets sold</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Gross sales</span>
                            </div>
                        </div>
                        {promos?.map((promo, index) => (
                            <TrackingLink key={index} promo={promo} handleShow={handleShow} hasPermission={hasPermission} isGAOnlyEvent={isGAOnlyEvent} />
                        ))}
                        <div className="total-row flex-row" role="row">
                            <div className="list-table-col list-table-col total-col" role="cell">
                                <span>Total</span>
                            </div>
                            <div className="list-table-col list-table-col total-col xl" role="cell">
                                <span>&nbsp;</span>
                            </div>
                            <div className="list-table-col list-table-col total-col" role="cell">
                                <span>{totalViews(promos)}</span>
                            </div>
                            <div className="list-table-col list-table-col total-col" role="cell">
                                <span>{totalTicketsSold(promos)}</span>
                            </div>
                            <div className="list-table-col list-table-col total-col" role="cell">
                                <span>{totalGross(promos)}</span>
                            </div>
                        </div>
                    </div>

                ) : (
                    <EmptyContainer>
                        <p>There are no events to track, create a new event to track its link.</p>
                    </EmptyContainer>
                )}
            </Card>

            <DeleteModal entity="tracking link" show={show} handleClose={handleClose} isRemoving={isRemoving} handleDelete={handleDelete} />
        </>
    );
}
