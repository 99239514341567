import React from "react"
import { useParams } from "react-router-dom";
import PackageDetailsWrapper from "../../components/PackageDetailsWrapper/PackageDetailsWrapper"

export default function PackageDetailsPage() {
  const { uuid } = useParams()
  return (
    <>
      <PackageDetailsWrapper uuid={uuid} />
    </>
  )
}