import { Route, Routes } from 'react-router-dom';

import { RequireAuth } from './context/Auth/Auth';

import {
    LoginPage,
    ForgotPasswordPage, SignUpPage, HomePage, ReportsPage, DashboardPage, MyEventPage, CreateEventPage, EventDetailsPage, EventSettingsPage, SeatMapPage, OffersPage, OrdersPage, AllOrdersPage, RefundOrderPage, RefundOrdersPage, PrimaryTicketsPage, ResaleTicketsPage, DailyTicketCountsPage, ContactAttendeesPage, GuestListPage, ViewGuestListPage, GuestInformationPage, TrackingLinksPage, AttendeesPage, SettingsPage, OrganizationInfoPage,
    TeamManagementPage, SecurityPage, PaymentInformationPage, PayoutsPage, TaxStatusPage, RegisterPage, CreatePackagesPage, MyPackagePage, PackageDetailsPage, PackageSettingsPage, PackageSeatmapPage
} from './pages';

import { ScrollToTop } from './components';

/**
 * @description Handle all the routes
 */

const Router = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/login/forgot-password" element={<ForgotPasswordPage />} />
                {["/signup", "/signup/organization-information", "/signup/team-management", "/signup/payment-information", "signup/tax-status"].map(path => (
                    <Route key={path} path={path} element={<SignUpPage />} />
                ))}
                <Route path="/package/:uuid" element={<RequireAuth><MyPackagePage /></RequireAuth>}>
                    <Route path="package-details" element={<PackageDetailsPage />} />
                    <Route path="package-settings" element={<PackageSettingsPage />} />
                    <Route path="package-seatmap" element={<PackageSeatmapPage />} />
                </Route>
                <Route path="/myevent/:uuid" element={<RequireAuth><MyEventPage /></RequireAuth>}>
                    <Route index element={<DashboardPage />} />
                    <Route path="event-details" element={<EventDetailsPage />} />
                    <Route path="event-settings" element={<EventSettingsPage />} />
                    <Route path="seatmap" element={<SeatMapPage />} />
                    {["offers", "offers/create", "offers/edit"].map(path => (
                        <Route key={path} path={path} element={<OffersPage />} />
                    ))}
                    <Route path="orders" element={<OrdersPage />}>
                        <Route index element={<AllOrdersPage />} />
                        <Route path="refund/all" element={<RefundOrdersPage />} />
                        <Route path="refund" element={<RefundOrderPage />} />
                    </Route>
                    <Route path="guest-list" element={<GuestListPage />} >
                        <Route index element={<ViewGuestListPage />} />
                        <Route path="add" element={<GuestInformationPage />} />
                    </Route>
                    <Route path="attendees" element={<AttendeesPage />} />
                    <Route path="primary-tickets" element={<PrimaryTicketsPage />} />
                    <Route path="resale-tickets" element={<ResaleTicketsPage />} />
                    {["daily-ticket-counts", "daily-ticket-counts/add", "daily-ticket-counts/edit"].map(path => (
                        <Route key={path} path={path} element={<DailyTicketCountsPage />} />
                    ))}
                    {["contact-attendees", "contact-attendees/add", "contact-attendees/edit"].map(path => (
                        <Route key={path} path={path} element={<ContactAttendeesPage />} />
                    ))}
                    {["tracking-links", "tracking-links/add"].map(path => (
                        <Route key={path} path={path} element={<TrackingLinksPage />} />
                    ))}
                </Route>
                <Route path="reports" element={<RequireAuth><ReportsPage /></RequireAuth>} />
                <Route path="events/create" element={<RequireAuth><CreateEventPage /></RequireAuth>} />
                <Route path="packages/create" element={<RequireAuth><CreatePackagesPage /></RequireAuth>} />
                <Route path="settings" element={<SettingsPage />}>
                    <Route path="organization-information" element={<OrganizationInfoPage />} />
                    <Route path="team-management" element={<TeamManagementPage />} />
                    <Route path="security" element={<SecurityPage />} />
                    <Route path="payment-information" element={<PaymentInformationPage />} />
                    <Route path="payouts" element={<PayoutsPage />} />
                    <Route path="tax-status" element={<TaxStatusPage />} />
                </Route>
            </Routes>
        </ScrollToTop>
    );
};

export default Router;
