import React from "react"
import { useParams } from "react-router-dom";
import PackageSeatmapWrapper from "../../components/PackageSeatmapWrapper/PackageSeatmapWrapper";

export default function PackageSeatmapPage() {
  const { uuid } = useParams()
  return (
    <>
      <PackageSeatmapWrapper uuid={uuid} />
    </>
  )
}