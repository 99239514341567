import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { PageLoadingContainer, Sidenav } from '../../components';

export default function MyPackagePage() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    <Sidenav />
                    <div className='spacer-md spacer-md--with-banner' id="main-content">
                        <Outlet />
                    </div>
                </>
            )}
        </>
  )
}