import React, { useRef, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'lodash/isEqual'

import LoadingContext from '../../context/Loading/Loading';
import authService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import { checkPermission } from '../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import CreatePackageButton from '../CreatePackageButton/CreatePackageButton';

import { createPackage, getOrgVenues } from '../../utilities/api';

import { Details } from './Details';

export default function PackagesWrapper({ packageId }) {
  const navigate = useNavigate();
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const [isSaving, setIsSaving] = useState(false)
  const { orgPermissions } = useContext(UserContext)
  const [hasPermission, setHasPermission] = useState(true);
  const [initialState, setInitialState] = useState();
  const [showFooter, setShowFooter] = useState(false)
  const nameRef = useRef()
  const descriptionRef = useRef()
  const organization = authService.getOrg()
	const [venues, setVenues] = useState([])
	const [room, setRoom] = useState()
	const [seatmap, setSeatmap] = useState()
	const [timezone, setTimezone] = useState()
	const [venue, setVenue] = useState([])

  const loadVenues = () => {
    getOrgVenues()
      .then((res) => setVenues(res?.data))
			.catch((err) => console.error(err))
  }

  const handleSubmit = (event) => {}

  const handleGoBack = () => {
      return navigate(`/`)
  }

  const handleSave = (e) => {
    let data = {
      name: nameRef.current.value,
      description: descriptionRef.current.value,
			roomId: room.id,
			seatmapId: Number(seatmap),
			venue: venue.id,
      organization: organization[0]?.id,
			timezone,
    }

    createPackage(data)
      .then((res) => navigate(`/package/${res?.data?.data.attributes.uuid}/package-details`))
      .catch((err) => console.error(err))
  }

  const formValid = () => {
    if (nameRef.current.value && nameRef.current.value.split('').length >= 3) {
      return setShowFooter(true)
    } 
    setShowFooter(false)
  }

	useEffect(() => {
		loadVenues()
	}, [])

  return (
    <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Create a Package</h1>
                        </header>
                        <Form onSubmit={handleSubmit}>
                            <Card body className='card--md'>
                                <Details nameRef={nameRef} descriptionRef={descriptionRef} formValid={formValid} venues={venues} setRoom={setRoom} room={room} setSeatmap={setSeatmap} seatmap={seatmap} setTimezone={setTimezone} venue={venue} setVenue={setVenue} />
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreatePackageButton
                            isEditing={packageId}
                            isSaving={isSaving}
                            showGoBack={!packageId}
                            styles={`${!packageId ? 'without-sidebar' : ' '} ${!hasPermission ? 'overlay' : ''} `}
                            handleSave={handleSave}
                            handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
        </>
  )
}