import React, { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';
import { QRCodeCanvas } from 'qrcode.react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { getEventStats } from "../../utilities/api";
import { checkPermission, getUrl } from '../../utilities/helpers';

import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

import { Reports } from '../Reports';
import { Cards } from './Cards';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function DashboardWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { isGAOnlyEvent } = useContext(EventDetailsContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [show, setShow] = useState(false)

    const [stats, setStats] = useState()

    const [range, setRange] = useState({
        timePeriod: '24_hrs',
        type: 'primary'
    })

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 4));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        getEvents();

        const socket = io(process.env.REACT_APP_AUTH);

        socket.on("connect", () => {
            socket.on(`newOrder:${eventId}`, (data) => {
                getEvents()
            })
        });
    }, [range])

    const getEvents = () => {
        getEventStats(eventId, range.timePeriod)
            .then((res) => {
                setStats(res.data)
                hideLoading();
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const handleShow = () => setShow(true)

    const handleClose = () => setShow(false)

    const onQRButtonClick = () => {
        const node = document.getElementById('qr');
        if (node == null) {
            return;
        }

        // For canvas, we just extract the image data and send that directly.
        const dataURI = node.toDataURL('image/png');

        downloadStringAsFile(dataURI, `${stats?.eventSeoUrl}.png`);
    }

    const downloadStringAsFile = (data, filename) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = data;
        a.click();
        handleClose();
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className="position-relative">
                        <div className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                            <Reports stats={stats} setRange={setRange} />
                            <Cards isGAOnlyEvent={isGAOnlyEvent} stats={stats} handleShow={handleShow} />
                        </div>
                        {!hasPermission && (
                            <NoPermissionsContainer />
                        )}
                    </div>

                    <Modal id="qr-modal" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop='static'>
                        <Modal.Body>
                            <div className="modal-body-heading">
                                <h1 className="modal-body-heading-title">QR Code</h1>
                            </div>
                            <Stack direction="horizontal" className="justify-content-center">
                                <QRCodeCanvas value={`${getUrl(`${process.env.REACT_APP_CONSUMER_PORTAL}/e/${stats?.eventSeoUrl}/${stats?.eventShortCode}`, isGAOnlyEvent)}`} id="qr" size={260} includeMargin={true} marginSize={2} level={'L'} />
                            </Stack>
                            <p>This generated code is unique to your event. You can export and share it in PNG format.</p>
                            <Stack direction="horizontal" className="btn-group-flex btn-group-flex-stretch">
                                <Button variant="outline-light" size="lg" onClick={handleClose}>Close</Button>
                                <Button size="lg" onClick={onQRButtonClick}>Export</Button>
                            </Stack>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    );
}
