import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import UserContext from '../../context/User/User';
import LoadingContext from '../../context/Loading/Loading';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import AuthService from '../../utilities/services/auth.service';

import { getCampaigns, removeCampaign } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import { ContactAttendeeWrapper } from './ContactAttendeeWrapper';
import { ContactAttendees } from './ContactAttendees'
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function ContactAttendeesWrapper({ eventId, type, id }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { event } = useContext(EventDetailsContext)

    const { orgPermissions } = useContext(UserContext)
    const { getPermissions } = AuthService;

    const navigate = useNavigate()
    const location = useLocation();

    const [hasPermission, setHasPermission] = useState(true);

    const [campaigns, setCampaigns] = useState([])

    // selected contact to edit 
    const [campaign, setCampaign] = useState()

    // flag to check whether changes to campaign has been made or new campaign has been created to check whether to fetch data again 
    // to tell if cancel button was clicked 
    const [isChangesMade, setIsChangesMade] = useState(false)

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 13));

    }, [orgPermissions])

    // only runs on initial load 
    useEffect(() => {
        getData()
    }, [])

    // on location change
    useEffect(() => {
        // viewing all campaigns  
        if (!location.pathname.includes('edit') && !location.pathname.includes('add')) {
            setCampaign()
            if (isChangesMade) {
                getData()
                setIsChangesMade(false)
            }
        }
    }, [location, isChangesMade])

    // get campaign on refresh if editing
    useEffect(() => {
        if (location.pathname.includes('edit') && !campaign) {
            setCampaign(campaigns.find(campaign => campaign.id === Number(id)))
        }
    }, [location, campaigns])

    // get all campaigns 
    const getData = () => {
        showLoading();
        getCampaigns(eventId)
            .then((res) => {
                setCampaigns(res.data)
                if (campaigns) hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    // create function that is a promise so button spinner works 
    const removeCampaigns = (id) => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            removeCampaign(id)
                .then((res) => {
                    let updatedCampaigns = campaigns.filter((c) => c.id !== id)
                    setCampaigns(updatedCampaigns)
                    setIsRemoving(false)
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    setIsRemoving(false)
                    reject()
                })
        })
    }

    // handle different views 
    const handleClick = (_, id, campaign) => {
        if (!id) {
            navigate('add')
            setCampaign()
        }
        else {
            setCampaign(campaign)
            navigate(`edit?id=${id}`)
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`${!hasPermission ? 'overlay' : ''}`}>
                        {(!location.pathname.includes('/add') && !location.pathname.includes('/edit')) ? (
                            <section className='max-width-wrapper'>
                                <header className='section-header'>
                                    <div className="section-header" >
                                        <div className="section-heading">
                                            <h1>Contact attendees</h1>
                                        </div>
                                        <p className='section-header-desc'>Communicate directly with your attendees via email</p>
                                    </div>
                                </header>
                                <ContactAttendees type={type} eventTimezone={event?.timezone} campaigns={campaigns} removeCampaigns={removeCampaigns} isRemoving={isRemoving} handleClick={handleClick} />
                            </section>
                        ) : (
                            <ContactAttendeeWrapper eventId={eventId} id={id} campaign={campaign} setIsChangesMade={setIsChangesMade} />
                        )}
                    </section>

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
