import React, { useEffect, useLayoutEffect, useState } from "react";

import { DndContext, useSensor, useSensors, MouseSensor } from "@dnd-kit/core";

import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import { ScalingMenu } from "./ScalingMenu";
import { FinancialInfo } from "./FinancialInfo";
import { InventoryMenu } from "./InventoryMenu";
import { Panel } from "./Panel";
import { SelectGASeatsModal } from "./SelectGASeatsModal";
import { MoveSeatsModal } from "./MoveSeatsModal";
import { DeleteModal } from "./DeleteModal";

import "./sidebar.scss";
import { useInventory } from "../../../InventoryProvider";

export default function Sidebar({
    isEventOnsale,
    tab,
    showSelectGASeats,
    setShowSelectGASeats,
    selectedGASectionId,
    selectUnassigned,
    handleAdd,
    getObjectTotalSeatCount,
    selectGASeats,
}) {
    const {
        setKills,
        kills,
        setHolds,
        holds,
        sold,
        priceLevels,
        setPriceLevels,
        offers,
        setOffers,
        selectedSeatIds,
        clearSelectedSeats,
        unassignedSeats,
        setUnassignedSeats,
        isDroppableInSelected
    } = useInventory();
    const [open, setOpen] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [objectToDelete, setObjectToDelete] = useState({});
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [destinationId, setDestinationId] = useState(null);
    // GA seat count in GA popup
    const [gaSeatCount, setGASeatCount] = useState(0);

    useEffect(() => {
        clearSelectedSeats();
    }, [tab]);

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    );

    // over.id
    // inventory: o-1, h-1, k-1, can also be sub like h-1-1
    // scaling: 1, 2 etc
    const handleDragEnd = (event) => {
        const { over } = event;

        if (over) {
            const destinationId = over.id;
            setDestinationId(destinationId);
            setShowMoveModal(true);
        }
    };

    const handleSubmitMoveModal = () => {
        if (destinationId) {
            if (tab === "scaling") {
                // move to a priceLevel
                scalingMoveSeats(destinationId);
            } else {
                // move to one of the inventories
                inventoryMoveSeats(destinationId);
            }
        }

        setShowMoveModal(false);
    };


    const removeSeatsFromScaling = () => {
        const groupedSeats = {}; // .seats and .gaSeat grouped by source
        const seatsToMove = [];
        const GASeatsToMove = {};

        // when this is used for GASeats seats is empty and wise versa
        const removeSeatsInScaling = (prevState, mainId, seats, gaSeats) => {
            const updatedItem = { ...prevState[mainId] };

            console.log("removeSeatsInScaling")
            console.log("updatedItem: ", updatedItem)

            if (seats) {
                updatedItem.seats = updatedItem.seats.filter(
                    (seatId) => !seats.includes(seatId)
                );
            }

            if (gaSeats && Object.keys(gaSeats).length > 0) {
                Object.entries(gaSeats).forEach(([sectionId, selectedGaSeats]) => {
                    if (updatedItem.gaSeats[sectionId]) {
                        updatedItem.gaSeats = Object.fromEntries(
                            Object.entries(updatedItem.gaSeats).filter(([sectionId]) => !gaSeats[sectionId])
                        );
                    }
                });
            }
            return { ...prevState, [mainId]: updatedItem };
        };

        const updateStateFunction = (stateSetter) => (mainId, seats, gaSeats) => {
            stateSetter((prevState) =>
                removeSeatsInScaling(prevState, mainId, seats, gaSeats)
            );
        };

        const updateUnassigned = updateStateFunction(setUnassignedSeats);
        const updatePriceLevels = updateStateFunction(setPriceLevels);

        // Helper function to add seats to the groupedSeats
        const addToGroup = (sourceId, seats = [], gaSeats = null) => {
            if (!groupedSeats[sourceId]) {
                groupedSeats[sourceId] = { seats: [], gaSeats: {} };
            }
            groupedSeats[sourceId].seats.push(...seats);
            if (gaSeats) {
                const { sectionId, gaSeats: sectionGaSeats } = gaSeats;
                if (!groupedSeats[sourceId].gaSeats[sectionId]) {
                    groupedSeats[sourceId].gaSeats[sectionId] = [];
                }
                groupedSeats[sourceId].gaSeats[sectionId].push(...sectionGaSeats);
            }
        };

        // Process regular seats
        if (selectedSeatIds.seats) {
            Object.entries(selectedSeatIds.seats).forEach(([sourceId, seats]) => {
                addToGroup(sourceId, seats);
            });
        }

        // Process GA seats
        if (selectedSeatIds.gaSeats) {
            Object.entries(selectedSeatIds.gaSeats).forEach(
                ([sectionId, sourceToSeats]) => {
                    Object.entries(sourceToSeats).forEach(([sourceId, gaSeats]) => {
                        addToGroup(sourceId, [], { sectionId, gaSeats });
                    });
                }
            );
        }

        // Process All groupedSeats
        Object.entries(groupedSeats).forEach(([sourceId, { seats, gaSeats }]) => {
            switch (sourceId) {
                case "unassigned": // Offers
                    updateUnassigned(sourceId, seats, gaSeats);
                    break;
                default:
                    console.log("updatePriceLevels: ", seats, gaSeats)
                    updatePriceLevels(sourceId, seats, gaSeats);
            }

            seatsToMove.push(...seats);
            Object.entries(gaSeats).forEach(([sectionId, sectionGaSeats]) => {
                if (!GASeatsToMove[sectionId]) {
                    GASeatsToMove[sectionId] = [];
                }
                GASeatsToMove[sectionId].push(...sectionGaSeats);
            });
        });

        return { seatsToMove, GASeatsToMove };
    };

    const removeSeatsFromPreviousInventories = () => {
        const groupedSeats = {}; // .seats and .gaSeat grouped by source
        const seatsToMove = [];
        const GASeatsToMove = {};

        // So here we already know which inventory it is, ( hold, kill etc )
        // so forGASeats we really only are interested in sectionId and the gaSeats ( to remove )
        // when this is used for GASeats seats is empty and wise versa
        const removeSeatsFromInventory = (
            prevState,
            mainId,
            categoryId,
            seats,
            gaSeats
        ) => {
            const updatedItem = { ...prevState[mainId] };

            // categoryId only exists for holds/kills
            if (categoryId) {
                updatedItem.categories = {
                    ...updatedItem.categories,
                    [categoryId]: {
                        ...updatedItem.categories[categoryId],
                    },
                };

                if (seats) {
                    updatedItem.categories[categoryId].seats = updatedItem.categories[
                        categoryId
                    ].seats.filter((seatId) => !seats.includes(seatId));
                }

                if (gaSeats && Object.keys(gaSeats).length > 0) {
                    updatedItem.categories[categoryId].gaSeats = {
                        ...updatedItem.categories[categoryId].gaSeats,
                    };

                    Object.entries(gaSeats).forEach(([sectionId, selectedGaSeats]) => {
                        if (updatedItem.categories[categoryId].gaSeats[sectionId]) {
                            updatedItem.categories[categoryId].gaSeats[sectionId] =
                                updatedItem.categories[categoryId].gaSeats[sectionId].filter(
                                    (seatId) => !selectedGaSeats.includes(seatId)
                                );
                        }
                    });
                }
            } else {
                if (seats) {
                    updatedItem.seats = updatedItem.seats.filter(
                        (seatId) => !seats.includes(seatId)
                    );
                }

                console.log("gaSeats", gaSeats);
                if (gaSeats && Object.keys(gaSeats).length > 0) {
                    updatedItem.gaSeats = {
                        ...updatedItem.gaSeats,
                    };

                    Object.entries(gaSeats).forEach(([sectionId, selectedGaSeats]) => {
                        if (updatedItem.gaSeats[sectionId]) {
                            updatedItem.gaSeats[sectionId] = updatedItem.gaSeats[
                                sectionId
                            ].filter((seatId) => !selectedGaSeats.includes(seatId));
                        }
                    });
                }
            }
            return { ...prevState, [mainId]: updatedItem };
        };

        const updateStateFunction =
            (stateSetter) => (mainId, categoryId, seats, gaSeats) => {
                stateSetter((prevState) =>
                    removeSeatsFromInventory(
                        prevState,
                        mainId,
                        categoryId,
                        seats,
                        gaSeats
                    )
                );
            };

        const updateOffers = updateStateFunction(setOffers);
        const updateHolds = updateStateFunction(setHolds);
        const updateKills = updateStateFunction(setKills);

        // Helper function to add seats to the groupedSeats
        const addToGroup = (sourceId, seats = [], gaSeats = null) => {
            if (!groupedSeats[sourceId]) {
                groupedSeats[sourceId] = { seats: [], gaSeats: {} };
            }
            groupedSeats[sourceId].seats.push(...seats);
            if (gaSeats) {
                const { sectionId, gaSeats: sectionGaSeats } = gaSeats;
                if (!groupedSeats[sourceId].gaSeats[sectionId]) {
                    groupedSeats[sourceId].gaSeats[sectionId] = [];
                }
                groupedSeats[sourceId].gaSeats[sectionId].push(...sectionGaSeats);
            }
        };

        // Process regular seats
        if (selectedSeatIds.seats) {
            Object.entries(selectedSeatIds.seats).forEach(([sourceId, seats]) => {
                if (sourceId === "unassigned") {
                    seatsToMove.push(...seats);
                    return;
                }
                addToGroup(sourceId, seats);
            });
        }

        // Process GA seats
        if (selectedSeatIds.gaSeats) {
            Object.entries(selectedSeatIds.gaSeats).forEach(
                ([sectionId, sourceToSeats]) => {
                    Object.entries(sourceToSeats).forEach(([sourceId, gaSeats]) => {
                        addToGroup(sourceId, [], { sectionId, gaSeats });
                    });
                }
            );
        }

        // Process All groupedSeats
        Object.entries(groupedSeats).forEach(([sourceId, { seats, gaSeats }]) => {
            const [sourceType, sourceMainId, sourceCategoryId] = sourceId.split("-");

            switch (sourceType) {
                case "o": // Offers
                    updateOffers(sourceMainId, null, seats, gaSeats);
                    break;
                case "h": // Holds
                    updateHolds(sourceMainId, sourceCategoryId, seats, gaSeats);
                    break;
                case "k": // Kills
                    updateKills(sourceMainId, sourceCategoryId, seats, gaSeats);
                    break;
                default:
                    console.error(`Unknown source type: ${sourceType}`);
            }

            seatsToMove.push(...seats);
            Object.entries(gaSeats).forEach(([sectionId, sectionGaSeats]) => {
                if (!GASeatsToMove[sectionId]) {
                    GASeatsToMove[sectionId] = [];
                }
                GASeatsToMove[sectionId].push(...sectionGaSeats);
            });
        });

        return { seatsToMove, GASeatsToMove };
    };

    const addSeatsInScaling = (
        destinationId,
        seatsToMove,
        GASeatsToMove
    ) => {
        const addSeatsInScalingInner = (
            prevState,
            mainId,
            seatsToMove,
            GASeatsToMove
        ) => {
            const updatedItem = { ...prevState[mainId] };

            updatedItem.seats = [...(updatedItem?.seats || []), ...seatsToMove];

            // Handle GA seats for main item
            if (GASeatsToMove) {
                updatedItem.gaSeats = updatedItem.gaSeats || {};
                Object.entries(GASeatsToMove).forEach(([sectionId, seats]) => {
                    updatedItem.gaSeats[sectionId] = [
                        ...(updatedItem.gaSeats[sectionId] || []),
                        ...seats,
                    ];
                });
            }

            return { ...prevState, [mainId]: updatedItem };
        };

        const updateStateFunction =
            (stateSetter) => (mainId, seatsToMove, GASeatsToMove) => {
                stateSetter((prevState) =>
                    addSeatsInScalingInner(prevState, mainId, seatsToMove, GASeatsToMove)
                );
            };

        // Destination can only be a priceLevel
        const updatePriceLevels = updateStateFunction(setPriceLevels);
        updatePriceLevels(destinationId, seatsToMove, GASeatsToMove);
    };

    const addSeatsToNewInventory = (
        destType,
        destMainId,
        destCategoryId,
        seatsToMove,
        GASeatsToMove
    ) => {
        const addSeatsToInventory = (
            prevState,
            mainId,
            categoryId,
            seatsToMove,
            GASeatsToMove
        ) => {
            const updatedItem = { ...prevState[mainId] };

            // Holds and kill might contain categories
            if (categoryId) {
                updatedItem.categories = {
                    ...updatedItem.categories,
                    [categoryId]: {
                        ...updatedItem.categories?.[categoryId],
                        seats: [
                            ...(updatedItem.categories?.[categoryId]?.seats || []),
                            ...seatsToMove,
                        ],
                    },
                };

                // Handle GA seats for categories
                if (GASeatsToMove) {
                    updatedItem.categories[categoryId].gaSeats =
                        updatedItem.categories[categoryId].gaSeats || {};
                    Object.entries(GASeatsToMove).forEach(([sectionId, seats]) => {
                        updatedItem.categories[categoryId].gaSeats[sectionId] = [
                            ...(updatedItem.categories[categoryId].gaSeats[sectionId] || []),
                            ...seats,
                        ];
                    });
                }
            } else {
                updatedItem.seats = [...(updatedItem.seats || []), ...seatsToMove];

                // Handle GA seats for main item
                if (GASeatsToMove) {
                    updatedItem.gaSeats = updatedItem.gaSeats || {};
                    Object.entries(GASeatsToMove).forEach(([sectionId, seats]) => {
                        updatedItem.gaSeats[sectionId] = [
                            ...(updatedItem.gaSeats[sectionId] || []),
                            ...seats,
                        ];
                    });
                }
            }

            return { ...prevState, [mainId]: updatedItem };
        };

        const updateStateFunction =
            (stateSetter) => (mainId, categoryId, seatsToMove, GASeatsToMove) => {
                stateSetter((prevState) =>
                    addSeatsToInventory(
                        prevState,
                        mainId,
                        categoryId,
                        seatsToMove,
                        GASeatsToMove
                    )
                );
            };

        const updateOffers = updateStateFunction(setOffers);
        const updateHolds = updateStateFunction(setHolds);
        const updateKills = updateStateFunction(setKills);

        switch (destType) {
            case "o": // Offers
                updateOffers(destMainId, null, seatsToMove, GASeatsToMove);
                break;
            case "h": // Holds
                updateHolds(destMainId, destCategoryId, seatsToMove, GASeatsToMove);
                break;
            case "k": // Kills
                updateKills(destMainId, destCategoryId, seatsToMove, GASeatsToMove);
                break;
            default:
                console.error(`Unknown destination type: ${destType}`);
        }
    };

    // We have access to holds, setHolds, kills, setKills, offers, setOffers and selectedSeatIds
    const inventoryMoveSeats = (destinationId) => {
        // Split the destinationId to get the type and indices
        const [destType, destMainId, destCategoryId] = destinationId.split("-");

        // This will remove all seats from previous inventories
        // Meaning if you move to the same inventory the seats were in
        // they are no longer in it once you move them there
        const { seatsToMove, GASeatsToMove } = removeSeatsFromPreviousInventories();

        addSeatsToNewInventory(
            destType,
            destMainId,
            destCategoryId,
            seatsToMove,
            GASeatsToMove
        );

        // Clear selected seats after moving them
        clearSelectedSeats();
    };

    const scalingMoveSeats = (destinationId) => {
        console.log("----")
        console.log("scalingMoveSeats----")
        console.log("----")
        // This will remove all seats from previous pl/unassigned
        // Meaning if you move to the same pl the seats were in
        // they are no longer in it once you move them there
        const { seatsToMove, GASeatsToMove } = removeSeatsFromScaling();
        addSeatsInScaling(
            destinationId,
            seatsToMove,
            GASeatsToMove
        );

        // Clear selected seats after moving them
        clearSelectedSeats();
    };

    const loggerFunc = () => {
        // console.log("");
    };

    const handleRemove = (id, removeFrom) => {
        console.log("handleRemove removeFrom: ", removeFrom);
        const objectToDelete = Object.values(removeFrom)[0];

        const hasSeatsOrGASeats = (obj) => {
            if (obj.seats && obj.seats.length > 0) return true;
            if (obj.gaSeats && Object.keys(obj.gaSeats).length > 0) return true;

            // Check categories recursively
            if (obj.categories) {
                return Object.values(obj.categories).some((category) =>
                    hasSeatsOrGASeats(category)
                );
            }

            return false;
        };

        // If the object to delete has anything inside of it, open modal
        if (hasSeatsOrGASeats(objectToDelete)) {
            setShowDeleteModal(true);
            setObjectToDelete({ ...objectToDelete, id: id });
        } else {
            // otherwise just continue and rm
            if (tab === "scaling") {
                scalingRemoveItem(id);
            } else {
                inventoryRemoveItem(id);
            }
        }
    };

    const handleDelete = () => {
        if (!objectToDelete?.id) return;
        if (tab === "scaling") {
            scalingRemoveItem(objectToDelete?.id);
        } else {
            inventoryRemoveItem(objectToDelete?.id);
        }

        setShowDeleteModal(false);
    };

    const scalingRemoveItem = (id) => {
        setPriceLevels((prevPriceLevels) => {
            const { [id]: removedLevel, ...restLevels } = prevPriceLevels;

            if (removedLevel && removedLevel?.seats) {
                moveSeatsToUnassigned(removedLevel.seats, removedLevel.gaSeats);
            }

            return restLevels;
        });
    };

    const inventoryRemoveItem = (id) => {
        const [type, mainId, categoryId] = id.split("-");

        // Removes categories and the item
        const moveAllSeatsToOpen = (item) => {
            if (item.categories) {
                Object.values(item.categories).forEach((category) => {
                    moveSeatsToOpen(category.seats, category.gaSeats);
                });
            }
            if (item.seats) {
                moveSeatsToOpen(item.seats, item.gaSeats);
            }
        };

        switch (type) {
            case "o": // Offers
                setOffers((prevOffers) => {
                    const { [mainId]: removedOffer, ...restOffers } = prevOffers;
                    moveSeatsToOpen(removedOffer.seats, removedOffer.gaSeats);
                    return restOffers;
                });
                break;

            case "h": // Holds
                setHolds((prevHolds) => {
                    if (categoryId) {
                        // Remove a category from a hold and move its seats to "open" offer
                        const holdToUpdate = prevHolds[mainId];
                        const categoryToRemove = holdToUpdate.categories[categoryId];
                        moveSeatsToOpen(categoryToRemove.seats, categoryToRemove.gaSeats);
                        return {
                            ...prevHolds,
                            [mainId]: {
                                ...holdToUpdate,
                                categories: Object.fromEntries(
                                    Object.entries(holdToUpdate.categories).filter(
                                        ([key]) => key !== categoryId
                                    )
                                ),
                            },
                        };
                    } else {
                        // Remove entire hold
                        const { [mainId]: removedHold, ...restHolds } = prevHolds;
                        moveAllSeatsToOpen(removedHold);
                        return restHolds;
                    }
                });
                break;

            case "k": // Kills
                setKills((prevKills) => {
                    if (categoryId) {
                        // Remove a category from a kill and move its seats to "open" offer
                        const killToUpdate = prevKills[mainId];
                        const categoryToRemove = killToUpdate.categories[categoryId];
                        moveSeatsToOpen(categoryToRemove.seats, categoryToRemove.gaSeats);
                        return {
                            ...prevKills,
                            [mainId]: {
                                ...killToUpdate,
                                categories: Object.fromEntries(
                                    Object.entries(killToUpdate.categories).filter(
                                        ([key]) => key !== categoryId
                                    )
                                ),
                            },
                        };
                    } else {
                        // Remove entire kill
                        const { [mainId]: removedKill, ...restKills } = prevKills;
                        moveAllSeatsToOpen(removedKill);
                        return restKills;
                    }
                });
                break;

            default:
                console.error(`Unknown item type: ${type}`);
        }
    };

    const moveSeatsToOpen = (seats, GASeatsToMove) => {
        setOffers((prevOffers) => {
            const updatedOffers = { ...prevOffers };
            for (const [key, offer] of Object.entries(updatedOffers)) {
                if (offer.name.toLowerCase() === "open") {
                    updatedOffers[key] = {
                        ...offer,
                        seats: [...offer.seats, ...seats],
                    };

                    // Handle GA seats
                    if (GASeatsToMove) {
                        updatedOffers[key].gaSeats = updatedOffers[key].gaSeats || {};
                        Object.entries(GASeatsToMove).forEach(([sectionId, seats]) => {
                            updatedOffers[key].gaSeats[sectionId] = [
                                ...(updatedOffers[key].gaSeats[sectionId] || []),
                                ...seats,
                            ];
                        });
                    }

                    break; // Exit the loop once we've found and updated the "open" offer
                }
            }
            return updatedOffers;
        });
    };

    const moveSeatsToUnassigned = (seats, gaSeats) => {
        setUnassignedSeats((prevUnassigned) => {
            const updatedUnassigned = { ...prevUnassigned };

            // Handle regular seats
            if (seats && seats.length > 0) {
                updatedUnassigned["unassigned"] = {
                    ...updatedUnassigned["unassigned"],
                    seats: [...(updatedUnassigned["unassigned"]?.seats || []), ...seats],
                };
            }

            // Handle GA seats
            if (gaSeats && Object.keys(gaSeats).length > 0) {
                updatedUnassigned["unassigned"] = {
                    ...updatedUnassigned["unassigned"],
                    gaSeats: {
                        ...(updatedUnassigned["unassigned"].gaSeats || {}),
                        ...Object.entries(gaSeats).reduce((acc, [sectionId, seatList]) => {
                            acc[sectionId] = [
                                ...(updatedUnassigned["unassigned"]?.gaSeats?.[sectionId] || []),
                                ...seatList,
                            ];
                            return acc;
                        }, {}),
                    },
                };
            }

            return updatedUnassigned;
        });
    };

    const closeGAModal = () => {
        setShowSelectGASeats(false);
    };

    // Here we are moving seat to selected
    // but we also need to store, where we are moving these seats to selected From
    const GAModalHandleSubmit = (e, inventoryList, sectionId) => {
        e.preventDefault();
        setShowSelectGASeats(false);
        console.log("GAModalHandleSubmit");

        const addToGASeats = {
            [sectionId]: {},
        };

        // For GASeats we need sectionId, which maps to FromKeys ( offer, kill etc )
        // and the seats
        inventoryList.forEach((obj) => {
            if (obj.count > 0) {
                // Take the first 'count' number of seats from obj.gaSeats
                addToGASeats[sectionId][obj.key] = obj.gaSeats.slice(0, obj.count);
            } else {
                addToGASeats[sectionId][obj.key] = [];
            }
        });

        console.log("inventoryList: ", inventoryList);
        console.log("sectionId: ", sectionId);
        console.log("addToGASeats: ", addToGASeats);

        selectGASeats(addToGASeats);
    };

    const handleCount = (e) => {
        setGASeatCount(e.target.value);
    };

    // get revenue of each price level
    // does not include information about gaSeats
    const sumRevenue = (level, allHoldKillSeats, allHoldKillGASections) => {
        const availableSeats = (level?.seats || []).filter(
            (seat) => !allHoldKillSeats.includes(seat)
        );
        const regularSeatsRevenue =
            parseFloat(level?.price) * availableSeats.length;

        const gaSeatsRevenue =
            Object.entries(level?.gaSeats || {}).reduce((acc, [sectionId, seats]) => {
                const availableGASeats =
                    seats.length - (allHoldKillGASections[sectionId]?.length || 0);
                return acc + (availableGASeats > 0 ? availableGASeats : 0);
            }, 0) * parseFloat(level?.price);

        return regularSeatsRevenue + gaSeatsRevenue;
    };

    const sumLevelRevenue = (level) => {
        const availableSeats = level?.seats;
        const regularSeatsRevenue =
            parseFloat(level?.price) * availableSeats.length;

        const gaSeatsRevenue =
            Object.entries(level?.gaSeats || {}).reduce((acc, [sectionId, seats]) => {
                const availableGASeats = seats?.length ?? 0;
                return acc + availableGASeats;
            }, 0) * parseFloat(level?.price);

        return regularSeatsRevenue + gaSeatsRevenue;
    }

    const sumRevenues = (levels) => {
        const allHoldKillSeats = [];
        const allHoldKillGASections = {};

        // Helper function to process seats and gaSeats
        const processCategory = (category) => {
            allHoldKillSeats.push(...category.seats);
            addToGASections(category.gaSeats);

            // Process subcategories if they exist
            if (category.categories) {
                for (const subCategory of Object.values(category.categories)) {
                    processCategory(subCategory);
                }
            }
        };

        // Helper function to add GA seats to allHoldKillGASections
        const addToGASections = (gaSeats) => {
            if (gaSeats) {
                for (const [sectionId, seats] of Object.entries(gaSeats)) {
                    if (!allHoldKillGASections[sectionId]) {
                        allHoldKillGASections[sectionId] = [];
                    }
                    allHoldKillGASections[sectionId].push(...seats);
                }
            }
        };

        // Process holds and kills
        for (const hold of Object.values(holds)) {
            processCategory(hold);
        }
        for (const kill of Object.values(kills)) {
            processCategory(kill);
        }

        const totalRevenue = Object.values(levels).reduce(
            (acc, level) =>
                acc + sumRevenue(level, allHoldKillSeats, allHoldKillGASections),
            0
        );
        return totalRevenue;
    };

    // sellable capacity -> all price level seats minus holds and kills seats
    const sumCapacity = () => {
        const allPriceLevelsSeats = Object.values(priceLevels).reduce(
            (acc, level) =>
                acc +
                level.seats.length +
                (level.gaSeats ? Object.values(level.gaSeats).flat().length : 0),
            0
        );

        // Helper function to count seats and gaSeats in a category and its subcategories
        const countCategorySeats = (category) => {
            let count = category.seats.length;

            // Count gaSeats
            if (category.gaSeats) {
                count += Object.values(category.gaSeats).flat().length;
            }

            // Recursively count seats and gaSeats in subcategories
            if (category.categories) {
                count += Object.values(category.categories).reduce(
                    (subAcc, subCategory) => subAcc + countCategorySeats(subCategory),
                    0
                );
            }

            return count;
        };

        const allHoldsSeats = Object.values(holds).reduce(
            (acc, hold) => acc + countCategorySeats(hold),
            0
        );

        const allKillsSeats = Object.values(kills).reduce(
            (acc, kill) => acc + countCategorySeats(kill),
            0
        );

        return allPriceLevelsSeats - allHoldsSeats - allKillsSeats;
    };

    // all price levels price * seats except for the current one - in the edit price and move popup
    const sumNewRevenues = (level, fromObjs, seatsToMove) => {
        let newPriceLevels = { ...priceLevels };

        // if fromObj, take away level seats from its seats array
        if (fromObjs?.length > 0) {
            {
                fromObjs.map((obj) => {
                    const newFromObj = {
                        ...obj,
                        seats: obj.seats.filter((seat) => !seatsToMove.includes(seat)),
                    };
                    newPriceLevels[obj.id] = newFromObj;
                });
            }
        }
        // get all price levels that have a price except for current one
        newPriceLevels = Object.values(newPriceLevels).filter(
            (l) => l.id !== level?.id
        );
        // add sum
        return sumRevenues(newPriceLevels);
    };

    return (
        <>
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                <aside className='sidebar sidebar-lg'>
                    <div className='sidebar-wrapper sidebar-wrapper-sm'>
                        {tab === "scaling" ? (
                            <ScalingMenu
                                canEdit={!isEventOnsale}
                                unassignedSeats={
                                    unassignedSeats && Object.values(unassignedSeats)[0]
                                }
                                selectUnassigned={selectUnassigned}
                                levels={priceLevels}
                                setLevels={setPriceLevels}
                                offers={offers}
                                handleAdd={handleAdd}
                                handleRemove={handleRemove}
                                sumRevenue={sumLevelRevenue}
                                sumNewRevenues={loggerFunc}
                                getObjectTotalSeatCount={getObjectTotalSeatCount}
                                isDroppableInSelected={isDroppableInSelected}
                            />
                        ) : (
                            <InventoryMenu
                                isEventOnsale={isEventOnsale}
                                holds={holds}
                                kills={kills}
                                offers={offers}
                                sold={sold}
                                handleRemove={handleRemove}
                                handleAdd={handleAdd}
                                getObjectTotalSeatCount={getObjectTotalSeatCount}
                                isDroppableInSelected={isDroppableInSelected}
                            />
                        )}
                        {priceLevels &&
                            Object.values(priceLevels)?.some(
                                (level) => level.seats.length > 0
                            ) && (
                                <div className='financial-info-container'>
                                    <div className='financial-info'>
                                        <Button
                                            variant='default'
                                            className='btn-toggle w-100 px-0'
                                            onClick={() => setOpen(!open)}
                                            aria-controls='financial-info-collapse'
                                            aria-expanded={open}
                                        >
                                            Financial information
                                        </Button>
                                        <Collapse in={open}>
                                            <div>
                                                <FinancialInfo
                                                    priceLevels={priceLevels}
                                                    sumRevenues={sumRevenues}
                                                    sumCapacity={sumCapacity}
                                                />
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                    </div>
                </aside>
                {Object.keys(selectedSeatIds).length > 0 && (
                    <Panel
                        selectedSeatIds={selectedSeatIds}
                        active={1}
                        handleRemove={clearSelectedSeats}
                    />
                )}
            </DndContext>

            <SelectGASeatsModal
                show={showSelectGASeats}
                selectedGASectionId={selectedGASectionId}
                hasError={false}
                handleSubmit={GAModalHandleSubmit}
                handleCancel={closeGAModal}
                handleClose={closeGAModal}
                offers={offers}
                kills={kills}
                holds={holds}
                gaSeatCount={gaSeatCount}
                handleCount={handleCount}
            />

            <MoveSeatsModal
                show={showMoveModal}
                id={0}
                selectedSeatIds={selectedSeatIds}
                destinationId={destinationId}
                tab={tab}
                sumRevenue={loggerFunc}
                sumNewRevenues={loggerFunc}
                handleClose={() => setShowMoveModal(false)}
                handleSubmit={handleSubmitMoveModal}
                getObjectTotalSeatCount={getObjectTotalSeatCount}
                priceLevels={priceLevels}
                offers={offers}
                holds={holds}
                kills={kills}
                unassignedSeats={unassignedSeats}
            />

            <DeleteModal
                show={showDeleteModal}
                fromObj={objectToDelete}
                handleClose={() => setShowDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
}
