import React from 'react';

import Nav from 'react-bootstrap/Nav';

import { DashboardMenu } from "./DashboardMenu";
import { SettingsMenu } from './SettingsMenu';
import { PackageMenu } from './PackageMenu';

import './sidenav.scss';

export default function Sidenav() {

    const isDashboard = /myevent/.test(window.location)
    const isPackage = /package/.test(window.location)

    return (
        <>
            <aside id="sidebarMenu" className={`sidebar ${isDashboard ? 'sidebar--with-banner' : ''}`}>
                <div className="sidebar-wrapper">
                    <Nav as="nav" className="position-sticky" activeKey={window.location.pathname}>
                        {isDashboard ? (
                            <DashboardMenu />
                        ) : (
                            isPackage ? <PackageMenu /> : <SettingsMenu />
                        )}
                    </Nav>
                </div>
            </aside>
        </>
    )
}