import React, { useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateInputWrapper } from '../../DateInputWrapper';
import { TimeInputWrapper } from '../../TimeInputWrapper';

export default function DateTime({ setPackageStart, packageStart, setPackageEnd, packageEnd, isEditable, formValid }) {
  return (
    <>
      <fieldset className='form-group'>
        <legend>On Sale Dates</legend>
        <Row>
            <Col>
                <DateInputWrapper
                    id="package-start"
                    isReadOnly={isEditable}
                    setDate={setPackageStart}
                    selectedDate={packageStart}
                    startDate={packageStart}
                    size="sm"
                />
            </Col>
            <Col>
                <TimeInputWrapper
                    id="package-start-time"
                    isReadOnly={isEditable}
                    setDate={setPackageStart}
                    selectedDate={packageStart}
                    size="sm"
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <DateInputWrapper
                    id="package-end"
                    isReadOnly={isEditable}
                    setDate={setPackageEnd}
                    selectedDate={packageEnd}
                    startDate={packageEnd}
                    size="sm"
                />
            </Col>
            <Col>
                <TimeInputWrapper
                    id="package-end-time"
                    isReadOnly={isEditable}
                    setDate={setPackageEnd}
                    selectedDate={packageEnd}
                    size="sm"
                />
            </Col>
        </Row>
      </fieldset>
    </>
  )
}