import React from 'react';

import { formatDateTime, formatAddress, formatCurrency, getTimezoneDate, sortAttractions } from '../../../../utilities/helpers';

import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';

import { WarningCard } from '../../../WarningCard';

import placeholder from '../../../../assets/placeholder.png'

export default function EventInfo({ event, start, isScheduled }) {

    return (
        <>
            <Image src={event?.image?.url || placeholder} rounded alt={`Cover art for ${event?.name} event`} width="160" height="160" className="event-image" />
            <Stack as="ul" gap={4} className='mt-4 small'>
                <li>
                    <p className="heading-sm">Event date</p>
                    <span>
                        <span>{formatDateTime(getTimezoneDate(start, event?.timezone), 'dateOnly')}</span>
                        <span className='time'>{formatDateTime(getTimezoneDate(start, event?.timezone), 'timeOnly')}</span>
                    </span>
                </li>
                <li>
                    <p className="heading-sm">Venue</p>
                    <span>{event?.venue?.name}
                        <span className='loc'>
                            {formatAddress(event?.venue)}</span>
                    </span>
                </li>
                <li>
                    <p className="heading-sm">Attractions</p>
                    <Stack as="ul">
                        {sortAttractions(event?.attractions)?.map((attraction, idx) => (
                            <li key={idx}>{attraction.name}</li>
                        ))}
                    </Stack>
                </li>
                {event?.pricingLevels && (
                    <li>
                        <p className="heading-sm">Base price</p>
                        <span className='fw-medium'>{formatCurrency(Math.min(...Object.values(event?.pricingLevels)?.map(pl => pl.price)))}<span className="to">{formatCurrency(Math.max(...Object.values(event?.pricingLevels)?.map(pl => pl.price)))}</span></span>
                    </li>
                )}
                <li>
                    <p className="heading-sm">Event ticket limit</p>
                    <span>Limit: <span className='text-muted'>{event?.globalTicketLimit || 'None'}</span></span>
                </li>
            </Stack>
            {!isScheduled && (
                <WarningCard text="This step is final and publishing the event is not reversible. Please make sure everything is correct before publishing it" margin="mt" size="lg" styles="card--light card-with-border" />
            )}
        </>
    );
}
