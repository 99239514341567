import React, { useEffect, useState, useRef } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

export default function Limits({incrementsRef, minimumRef, maximumRef, validateForm, currentPackage }) {
  return (
    <>
    <div className="card-body-heading card-body-heading--flex">
        <Card.Title as="h5">Ticket limits</Card.Title>
    </div>
    <Row>
        <Col>
          <Form.Control placeholder="Minimum" ref={minimumRef} type="number" onChange={validateForm} defaultValue={currentPackage ? currentPackage?.attributes.ticketMinimum : ''} required />
        </Col>
        <Col>
          <Form.Control placeholder="Maximum" ref={maximumRef} type="number" onChange={validateForm} defaultValue={currentPackage ? currentPackage?.attributes.ticketMaximum : ''} required />
        </Col>
        <Col>
          <Form.Control placeholder="In Increments Of" ref={incrementsRef} type="number" onChange={validateForm} defaultValue={currentPackage ? currentPackage?.attributes.incrementsOf : ''} required />
        </Col>
      </Row>
    </>
  )
}