import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import AuthService from '../../../utilities/services/auth.service';

import UserContext from '../../../context/User/User';
import EventDetailsContext from '../../../context/EventDetails/EventDetails';

import { getOrders, refundOrders } from '../../../utilities/api';
import { checkPermission, formatCurrency, formatNumber, getTransactionTypes } from "../../../utilities/helpers";

import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from "../../ExportBtn";
import { OrderSummary } from '../../OrderSummary';
import { EmptyContainer } from '../../EmptyContainer';
import { LoadingContainer } from '../../LoadingContainer';

export default function OrdersList({ eventId, details }) {

    const { orgPermissions } = useContext(UserContext)

    const { event } = useContext(EventDetailsContext)

    const { getPermissions } = AuthService;

    const orderOpts = [
        {
            label: 'All orders',
            value: 'all'
        },
        ...getTransactionTypes()
    ];

    const [hasRefundPermission, setHasRefundPermission] = useState();

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const [orderStatus, setOrderStatus] = useState(orderOpts[0].value);

    const [filteredDetails, setFilteredDetails] = useState(details);

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasRefundPermission(checkPermission(orgPermissions, getPermissions(), 9));

    }, [orgPermissions])

    useEffect(() => {
        setIsLoading(true)
        getFilteredOrders()
    }, [query, orderStatus])


    const getFilteredOrders = () => {
        getOrders(eventId, query, orderStatus)
            .then((res) => {
                setFilteredDetails(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    const getText = () => {
        const obj = orderOpts.find(opt => opt.value === orderStatus)

        if (obj.value == 'all') {
            return
        } else {
            return obj.value
        }
    }

    // sort by date
    const sortBy = arr => {
        return arr.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b?.processedAt) - new Date(a?.processedAt);
        });
    }

    const refund = (orderUUID, reason) => {
        let data = {
            orderUUID,
            reason
        }

        refundOrders(data)
            .then((res) => getFilteredOrders())
            .catch((err) => console.error(err))
    }

    return (
        <>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <FloatingLabel controlId="orders" label="Orders">
                        <Form.Select value={orderStatus} onChange={(e) => setOrderStatus(e.target.value)} aria-label="View Orders">
                            {orderOpts.map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                    <SearchBar getQuery={setQuery} placeholder="Search by Order #" />
                    <ExportBtn data={filteredDetails.orders} timezone={event?.timezone} exportedData="orders" />
                </div>
            </header>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    <Stack direction="horizontal" className='mb-4 split-row'>
                        <Stack as="ul" direction="horizontal" className="horizontal-list">
                            <li>
                                Gross sales
                                <span>{formatCurrency(filteredDetails.grossSales)}</span>
                            </li>
                            <li>
                                Orders
                                <span>{formatNumber(filteredDetails.count)}</span>
                            </li>
                            <li>
                                Attendees
                                <span>{formatNumber(filteredDetails.attendeesCount)}</span>
                            </li>
                        </Stack>
                        {/* <Link to="refund/all" className={`btn btn-outline-light ${!hasRefundPermission && 'btn-link-disabled'}`}>Issue multiple refunds</Link> */}
                    </Stack>
                    {filteredDetails?.orders.length > 0 ? (
                        <Stack as="ul" gap={4}>
                            {sortBy(filteredDetails?.orders).map((order, index) => (
                                <OrderSummary key={index} timezone={event?.timezone} order={order} hasPermission={hasRefundPermission} submitRefund={refund} />
                            ))}
                        </Stack>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>This event has no {getText()} orders {query && `starting with '${query}'`}. Please try again.</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}
