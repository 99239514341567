import React from "react";
import { Rect } from "react-konva";

export default function Seat({
  selectSeat,
  seat,
  activeMapAction,
  hovering,
  darkenColor,
  seatMouseToggle,
  seatInfo,
}) {
    const isSeatSold = seatInfo?.name?.toLowerCase() === "sold";
  const seatColor = seatInfo?.color || "#E6E8EC";

  const handleMouseEnter = (e) => {
    if (isSeatSold) return;
    const container = e.target.getStage().container();
    container.style.cursor = "pointer";
    seatMouseToggle(seat.seatId, true);
  };

  const handleMouseLeave = (e) => {
    if (isSeatSold) return;
    const container = e.target.getStage().container();
    container.style.cursor = "default";
    seatMouseToggle(seat.seatId, false);
  };

  const handleClick = () => {
    if (isSeatSold) return;
    selectSeat(seat.seatId);
  };

  return (
    <Rect
      x={seat.cx}
      y={seat.cy}
      id={seat.seatId}
      cornerRadius={20}
      className='seat'
      width={seat.w}
      height={seat.h}
      fill={hovering && !isSeatSold ? darkenColor(seatColor) : seatColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      listening={![1, 2, 4].includes(activeMapAction) && !isSeatSold}
    ></Rect>
  );
}
