import React, { useState, useEffect } from 'react';

import { formatCurrency, formatNumber, copy } from '../../../utilities/helpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { InfoIcon } from '../../InfoIcon';

import './cards.scss';

export default function Cards({ isGAOnlyEvent, stats, handleShow }) {

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000)
        }
    }, [copied])

    return (
        <section className='cards'>
            <Row>
                <Col xs={6}>
                    <Card body id="tickets-sold-card">
                        <div className='heading--flex card-body-heading'>
                            <h1 className='card-body-title card-body-title--flex tickets-sold'>Tickets sold</h1>
                            <Badge bg="default" className='badge-outline badge-outline--primary'>Primary</Badge>
                        </div>
                        <Row className='mb-3'>
                            <Col>
                                <span className="amount">{formatNumber(stats?.allTicketsSold)}</span> <span className='text-muted'>/ {formatNumber(stats?.totalTickets)}</span>
                            </Col>
                            <Col className='text-end'>
                                <span className='fw-medium'>{stats?.totalSoldPercentage}%</span>
                            </Col>
                        </Row>
                        <ProgressBar now={stats?.totalSoldPercentage} />
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card body>
                        <div className='heading--flex card-body-heading total-sales mb-4'>
                            <Stack>
                                <Stack direction="horizontal" className="small-label--flex">
                                    <span className='small-label'>Total sales</span>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Total sales include net sales and royalties.</Tooltip>}>
                                        <Button variant="link">
                                            <InfoIcon variant="dark" />
                                        </Button>
                                    </OverlayTrigger>
                                </Stack>
                                <h1 className='card-body-title card-body-title-lg'>{formatCurrency(stats?.totalSales)}</h1>
                            </Stack>
                        </div>
                        <Row className='split-row'>
                            <Col>
                                <span className='amount'>{formatCurrency(stats?.primaryNetSales)}</span> <span className="small-text">Net sales</span>
                            </Col>
                            <Col className='text-end flex-grow-0'>
                                <Badge bg="default" className='badge-outline badge-outline--primary'>Primary</Badge>
                            </Col>
                        </Row>
                        <Row className='mt-2 split-row'>
                            <Col>
                                <span className='amount'>{formatCurrency(stats?.resaleRoyalties)}</span> <span className="small-text">Royalties</span>
                            </Col>
                            <Col className='text-end flex-grow-0'>
                                <Badge bg="default" className='badge-outline badge-outline--secondary'>Resale</Badge>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col xs={6}>
                    <Card body>
                        <div className='heading--flex card-body-heading royalties mb-4'>
                            <Stack>
                                <Stack direction="horizontal" className="small-label--flex">
                                    <span className='small-label'>Royalites</span>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Royalties that have been collected from resales.</Tooltip>}>
                                        <Button variant="link">
                                            <InfoIcon variant="dark" />
                                        </Button>
                                    </OverlayTrigger>
                                </Stack>
                                <h1 className='card-body-title card-body-title-lg'>{formatCurrency(stats?.resaleRoyalties)}</h1>
                            </Stack>
                            <Badge bg="default" className='badge-outline badge-outline--secondary'>Resale</Badge>
                        </div>
                        <Row>
                            <Col>
                                <span className='amount'>{formatNumber(stats?.resaleTicketsSold)}</span> <span className="small-text">Tickets sold</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card body>
                        <div className='heading--flex card-body-heading net-sales mb-4'>
                            <Stack>
                                <Stack direction="horizontal" className="small-label--flex">
                                    <span className='small-label'>Net sales</span>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Sales from your event after fees have been paid out.</Tooltip>}>
                                        <Button variant="link">
                                            <InfoIcon variant="dark" />
                                        </Button>
                                    </OverlayTrigger>
                                </Stack>
                                <h1 className='card-body-title card-body-title-lg'>{formatCurrency(stats?.primaryNetSales)}</h1>
                            </Stack>
                            <Badge bg="default" className='badge-outline badge-outline--primary'>Primary</Badge>
                        </div>
                        <Row>
                            <Col>
                                <span className='amount'>{formatCurrency(stats?.primaryGrossSales)}</span> <span className="small-text">Gross sales</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col xs={3}>
                    <Card body>
                        <div className="card-body-heading page-views mb-0">
                            <Stack direction="horizontal" className="small-label--flex">
                                <span className='small-label'>Page views</span>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>How many times your event URL has been viewed.</Tooltip>}>
                                    <Button variant="link">
                                        <InfoIcon variant="dark" />
                                    </Button>
                                </OverlayTrigger>
                            </Stack>
                            <h1 className='card-body-title'>{formatNumber(stats?.pageViews)}</h1>
                        </div>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card body>
                        <div className="card-body-heading payout mb-0">
                            <Stack direction="horizontal" className="small-label--flex">
                                <span className='small-label'>Your payouts</span>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Balance of funds you’ll be paid out including total sales and facility fees.</Tooltip>}>
                                    <Button variant="link">
                                        <InfoIcon variant="dark" />
                                    </Button>
                                </OverlayTrigger>
                            </Stack>
                            <h1 className='card-body-title'>{formatCurrency(stats?.totalSalesWithPayouts)}</h1>
                        </div>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card body>
                        <div className='card-body-heading shareable-link mb-0'>
                            <Stack gap={3}>
                                <h1 className='card-body-title'>Shareable link</h1>
                                <Button variant="outline-light" className={copied ? 'btn-copy--success' : ''} onClick={() => copy(`${process.env.REACT_APP_CONSUMER_PORTAL}/e/${stats?.eventSeoUrl}/${stats?.eventShortCode}`, setCopied, isGAOnlyEvent)}>
                                    {!copied && 'Copy URL'}
                                </Button>
                            </Stack>
                        </div>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card body>
                        <div className="card-body-heading qrcode mb-0">
                            <Stack gap={3}>
                                <h1 className='card-body-title'>QR Code</h1>
                                <Button onClick={handleShow} variant="outline-light">
                                    View
                                </Button>
                            </Stack>
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    );
}
